import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import axios from "axios";
import { SERVER_URL } from '../../../config';
import countries from '../../../CountryCode.json';
import states from '../../../States.json';
import toast, { Toaster } from "react-hot-toast";
import 'react-toastify/dist/ReactToastify.css';

export default function Account() {
    const user = useSelector((state) => state.user);
    const email = user[0]?.email;
    const CusID = user[0]?.CusID;

    const [isEditable, setIsEditable] = useState(false);  // Initially not editable
    const [userData, setUserData] = useState({});
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');

    const fetchDatas = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getUsersDatas?CusID=${CusID}`);
            if (response.data && response.status === 200) {
                const data = response.data.data[0];
                setUserData(data);
                setSelectedCountry(data.country);
                setSelectedState(data.city);
            } else {
                console.error("Failed to fetch user data:", response.data.message);
            }
        } catch (err) {
            console.error("Error fetching user data:", err);
        }
    };

    useEffect(() => {
        if (CusID) {
            fetchDatas();
        }
    }, [CusID]);

    const handleUpdateProfile = async () => {
        const formData = new FormData();
        formData.append('CusID', CusID);
        formData.append('name', userData.name);
        formData.append('country', userData.country);
        formData.append('mobile_number', userData.Cust_PhoneNo);
        formData.append('dob', formatDate(userData.Cust_DOB));
        formData.append('address', userData.Cust_Address);
        formData.append('city', userData.Cust_City);

        if (file) {
            formData.append('profile_photo', file);
        }

        try {
            const response = await axios.post(`${SERVER_URL}/api/updateProfile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status === true) {
                console.log('Profile updated successfully');
                toast.success('Profile updated successfully');
                setIsEditable(false);

                setTimeout(() => {
                    fetchDatas();
                }, 2000);
            } else {
                console.error("Failed to update profile:", response.data.message);
                toast.error('Error updating profile, Try again');
            }
        } catch (err) {
            console.error("Error updating profile:", err);
            toast.error('Error updating profile, Try again');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        setFileName(selectedFile ? selectedFile.name : '');
    };

    const handleCountryChange = (e) => {
        const country = e.target.value;
        setSelectedCountry(country);
        setSelectedState('');
        setUserData((prevData) => ({ ...prevData, country }));
    };

    const handleStateChange = (e) => {
        const state = e.target.value;
        setSelectedState(state);
        setUserData((prevData) => ({ ...prevData, Cust_City: state }));
    };

    const filteredStates = states.filter(state => state.country_name === selectedCountry);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        const formattedDate = new Date(selectedDate).toISOString().split('T')[0];
        setUserData((prevData) => ({ ...prevData, Cust_DOB: formattedDate }));
    };

    const handleCancel = () => {
        setIsEditable(false);
        fetchDatas();  // Reset the form to the fetched data
    };

    const handleEditProfile = () => {
        setIsEditable(true);  // Enable editing when the user clicks "Edit Profile"
    };

    return (
        <div>
            <div data-aos="fadeIn" data-aos-duration='2000'>
                <p className='mt-5 font-bold text-xl uppercase'>Profile Details</p>
                <div className='flex flex-col lg:flex-row gap-3 md:gap-5 mt-3'>
                </div>

                <div className='flex flex-col md:flex-row gap-7 mt-8'>
                    <div className='grid gap-3 md:w-[50%]'>
                        {/* <div className='w-[100%] py-2 rounded-md border border-white/75 bg-transparent px-5 cursor-not-allowed'>
                            <p className='text-xs 2xl:text-sm text-white/70'>Member</p>
                            <p className='font-semibold'>{CusID}</p>
                        </div> */}

                        <div className={`w-[100%] py-2 rounded-md border border-white/75 bg-transparent px-5 ${!isEditable ? 'cursor-not-allowed' : ''}`}>
                            <p className='text-xs 2xl:text-sm text-white/70'>Full Name</p>
                            <input
                                name='name'
                                className='w-full bg-transparent outline-none'
                                value={userData.name || ''}
                                onChange={handleChange}
                                disabled={!isEditable}
                            />
                        </div>

                        <div className={`w-[100%] py-2 rounded-md border border-white/75 bg-transparent px-5 ${!isEditable ? 'cursor-not-allowed' : ''}`}>
                            <p className='text-xs 2xl:text-sm text-white/70'>Country</p>
                            <select
                                name='country'
                                className='w-full bg-transparent outline-none'
                                value={userData.country || ''}
                                onChange={handleCountryChange}
                                disabled={!isEditable}
                            >
                                <option className='text-black'>--- Select ---</option>
                                {countries.map((country) => (
                                    <option key={country.country_code} value={country.country_name} className='text-black'>
                                        {country.country_name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* <div className='w-[100%] py-2 h-[58px] rounded-md border border-white/75'>
                            <p className='text-xs 2xl:text-sm text-white/70 px-5'>Sponsor ID</p>
                            <input
                                name='withdraw_wallet2'
                                className={`w-full bg-transparent px-5 outline-none placeholder:text-white ${!user ? '' : 'cursor-not-allowed'}`}
                                placeholder='Withdraw Address 2 (USDT -TRC20)'
                                value={userData.sponsorid || ''}
                                disabled={user || !isEditable}
                            />
                        </div> */}
                        <div className={`w-[100%] py-2 rounded-md border border-white/75 bg-transparent px-5 ${!isEditable ? 'cursor-not-allowed' : ''}`}>
                            <p className='text-xs 2xl:text-sm text-white/70'>Date of Birth</p>
                            <input
                                type='date'
                                name='Cust_DOB'
                                className='bg-transparent outline-none'
                                value={userData.Cust_DOB ? formatDate(userData.Cust_DOB) : ''}
                                onChange={handleDateChange}
                                disabled={!isEditable}
                            />
                        </div>
                    </div>

                    <div className='grid gap-3 md:w-[50%]'>
                        <div className='w-[100%] py-2 rounded-md border border-white/75 bg-transparent px-5 cursor-not-allowed'>
                            <p className='text-xs 2xl:text-sm text-white/70'>Email</p>
                            <p className='font-semibold'>{email}</p>
                        </div>

                        <div className='w-[100%] gap-7 flex'>
                            <div className='w-[20%] h-[58px] py-2 rounded-md border border-white/75 bg-transparent px-5'>
                                <p className='text-xs 2xl:text-sm text-white/70'>Code</p>
                                <p className='font-semibold'>{countries.find(c => c.country_name === userData.country)?.country_code || ''}</p>
                            </div>
                            <input
                                name="Cust_PhoneNo"
                                className="w-[80%] outline-none py-2 rounded-md border border-white/75 bg-transparent px-5 placeholder:text-white"
                                placeholder="Mobile No"
                                value={userData.Cust_PhoneNo || ''}
                                onChange={(e) => handleChange(e)}
                                disabled={!isEditable}
                            />
                        </div>

                        <div className={`w-[100%] py-2 rounded-md border border-white/75 bg-transparent px-5 ${!isEditable ? 'cursor-not-allowed' : ''}`}>
                            <p className='text-xs 2xl:text-sm text-white/70'>State</p>
                            <select
                                name='city'
                                className='w-full bg-transparent outline-none'
                                value={userData.Cust_City || ''}
                                onChange={handleStateChange}
                                disabled={!isEditable}
                            >
                                <option className='text-black'>Select the City</option>
                                {filteredStates.map((state) => (
                                    <option key={state.name} value={state.name} className='text-black'>
                                        {state.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* <div className={`w-[100%] py-2 rounded-md border border-white/75 bg-transparent px-5 ${!isEditable ? 'cursor-not-allowed' : ''}`}>
                            <p className='text-xs 2xl:text-sm text-white/70'>Date of Birth</p>
                            <input
                                type='date'
                                name='Cust_DOB'
                                className='bg-transparent outline-none'
                                value={userData.Cust_DOB ? formatDate(userData.Cust_DOB) : ''}
                                onChange={handleDateChange}
                                disabled={!isEditable}
                            />
                        </div> */}
                    </div>
                </div>

                <div className='w-[100%] py-2 mt-3 overflow-hidden mb-5 h-[100px] rounded-md border border-white/75'>
                    <p className='text-xs 2xl:text-sm text-white/70 px-5'>Address</p>
                    <textarea
                        name='Cust_Address'
                        className={`w-full h-full px-5 py-2 bg-transparent outline-none placeholder:text-white`}
                        placeholder='Customer Address'
                        value={userData.Cust_Address || ''}
                        onChange={handleChange}
                        disabled={!isEditable}
                    ></textarea>
                </div>

                <div className='flex justify-center'>
                    <div className='flex gap-5 mt-3'>
                        {isEditable ? (
                            <>
                                <button
                                    onClick={handleUpdateProfile}
                                    className='bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-sm font-semibold px-10 py-3 rounded-md'
                                >
                                    Update Profile
                                </button>
                                <button
                                    className='bg-gray-300 text-black px-4 py-2 text-sm font-semibold rounded-md shadow-md shadow-black/30 ml-2'
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </button>
                            </>
                        ) : (
                            <button
                                className='bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-sm font-semibold px-10 py-3 rounded-md'
                                onClick={handleEditProfile}
                            >
                                Edit Profile
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
