import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

export default function Plans() {

    const navigate = useNavigate();

    const user = useSelector((state) => state.user);
    const emailID = user ? user[0].email : '';

    const [membershipPlans, setMembershipPlans] = useState([
        {
            name: "Affiliate",
            description:
                "A perfect starting point to begin your journey with MBC. Upgrade anytime to unlock more benefits!",
            price: "$7",
            frequency: "One-Time",
            benefits: [
                "Earn 10% commission per referral.",
                "Access basic features to explore the MBC ecosystem.",
            ],
        },
        {
            name: "Basic",
            description: "A great choice for those looking to grow and learn.",
            price: "$50",
            frequency: "Per Year",
            benefits: [
                "Access to multiple income streams.",
                "Educational resources and tools tailored for entrepreneurs.",
            ],
        },
        {
            name: "Silver",
            description: "Unlock greater value with this premium tier.",
            price: "$200",
            frequency: "Per Year",
            benefits: [
                "Partial travel access with exclusive discounts on hotels and cruises.",
                "Enhanced earning opportunities and resources.",
            ],
        },
        {
            name: "Gold",
            description: "Ideal for serious entrepreneurs aiming for higher success.",
            price: "$600",
            frequency: "Per Year",
            benefits: [
                "Full travel access to the best hotel and cruise deals worldwide.",
                "Advanced earning and learning tools.",
            ],
        },
        {
            name: "Platinum",
            description: "Tailored for visionaries and high-achievers.",
            price: "$1,500",
            frequency: "Per Year",
            benefits: [
                "Exclusive access to partner projects and premium services.",
                "Top-tier benefits and networking opportunities.",
            ],
        },
        {
            name: "Executive",
            description: "Perfect for experienced entrepreneurs seeking growth and elite benefits.",
            price: "$3,000",
            frequency: "Per Year",
            benefits: [
                "Full Travel Portal Access to exclusive deals on hotels and cruises.",
            ],
        },
        {
            name: "Ambassador",
            description: "Designed for established leaders looking for advanced opportunities.",
            price: "$5,000",
            frequency: "Per Year",
            benefits: [
                "Full Travel Portal Access for guaranteed best travel deals worldwide.",
                "Top-tier benefits and networking opportunities.",
            ],
        },
        {
            name: "President",
            description: "For visionaries and elite professionals aiming for unparalleled success.",
            price: "$10,000",
            frequency: "Per Year",
            benefits: [
                "Full Travel Portal Access to the finest hotel and cruise options globally.",
            ],
        },
    ]);

    const handleRedirect = () => {
        if (emailID) {
            navigate("/User/dashboard");
        } else {
            navigate("/signup");
        }
    };


    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className="absolute z-10 top-1/2 transform -translate-y-1/2 -left-5 md:-left-10 w-10 h-10 rounded-full bg-black cursor-pointer duration-500 hover:bg-gradient-to-r from-[#E43875] to-[#F98736]"
        >
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon
                    icon={faArrowLeft}
                    className="text-white font-bold text-xl"
                />
            </div>
        </div>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className="absolute top-1/2 transform -translate-y-1/2 -right-5 md:-right-10 w-10 h-10 rounded-full bg-black cursor-pointer duration-500 hover:bg-gradient-to-r from-[#E43875] to-[#F98736]"
        >
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon
                    icon={faArrowRight}
                    className="text-white font-bold text-xl"
                />
            </div>
        </div>
    );

    const settings = {
        infinite: true,
        speed: 500,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div>
            <div className="pt-28 relative">
                <h1
                    className="text-3xl md:text-4xl lg:text-5xl font-bold text-center text-shadow-custom"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                >
                    Our Membership Plan’s
                </h1>
                <h2 className="text-xl font-bold text-center mt-3">
                    100% Payout for 90 Days
                </h2>
                <div className="w-[85%] mx-auto mt-7">
                    <Slider className="" {...settings}>
                        {membershipPlans.map((plan, index) => (
                            <div className="px-1 lg:px-3" key={index}>
                                <div className=" group bg-white rounded-2xl border text-black hover:text-white duration-300 hover:bg-gradient-to-b from-[#E43875] to-[#F98736] h-[480px] 2xl:h-[450px] grid content-around py-10 px-7">
                                    <div className="flex items-center gap-4">
                                        <div className="rounded-lg bg-[#FFEEF4] p-3 grid place-content-center">
                                            <div className="w-6 h-6 bg-gradient-to-b from-[#E43875] to-[#F98736] rounded-full"></div>
                                        </div>
                                        <h1 className="text-lg font-bold">{plan.name}</h1>
                                    </div>
                                    <p className="mt-3 text-sm 2xl:text-base">{plan.description}</p>
                                    <div className="mt-4 flex items-end gap-1">
                                        <h1 className="bg-gradient-to-b from-[#E43875] to-[#F98736] text-transparent bg-clip-text text-3xl md:text-4xl font-bold group-hover:text-white">
                                            {plan.price}
                                        </h1>
                                        <span className="text-gray-500 group-hover:text-white">
                                            / {plan.frequency}
                                        </span>
                                    </div>
                                    <div>
                                        <h2 className="font-bold mt-5">Membership Benefits</h2>
                                        {plan.benefits.map((benefit, i) => (
                                            <div className="flex gap-3 items-center mt-2" key={i}>
                                                <i className="fa-solid fa-circle-check text-green-500 group-hover:text-white"></i>
                                                <p className="text-sm">{benefit}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="mt-7">
                                        <button className="py-3 w-full rounded-full font-bold bg-gradient-to-b from-[#E43875] to-[#F98736] text-white button-hover-effect" onClick={handleRedirect}>
                                            Get started
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
                <p className="mt-10 text-center underline"><span className="font-bold">Note:</span> Upgrade your membership within 90 days by paying the difference to access the next level of benefits.</p>
            </div>
        </div>
    );
}
