import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SERVER_URL } from '../../config';

const Travelredirect = () => {
  const [userDetails, setUserDetails] = useState(null); // Store user details after fetching
  const [loading, setLoading] = useState(false); // State for showing the loader
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  const CusID = queryParams.get("CusID");
  const planId = parseInt(queryParams.get("planId"));

  useEffect(() => {
    // Function to store travel data
    const sendTravelData = async () => {
      setLoading(true); // Show loader before starting the API call
      try {
        const response = await axios.post(`${SERVER_URL}/api/storeTravelData`, { CusID, planId });
        console.log(response,'response')

        // Handle success response
        // toast.success(response.data.message);

        // After storing travel data, fetch user details
        await fetchUserDetails(CusID);
      } catch (error) {
        // Handle errors
        if (error.response) {
          toast.error(`Error: ${error.response.data.message}`);
        } else {
          toast.error("An error occurred. Please try again.");
        }
        setLoading(false); // Hide loader if there is an error
      }
    };

    // Function to fetch user details and handle external API calls
    const fetchUserDetails = async (CusID) => {
      try {
        const response = await axios.post(`${SERVER_URL}/api/getDetailsAndUpdate`, { CusID });
        // Store user details in state
        setUserDetails(response.data.data);

        if (response.data.url) {
          // Stay on this page for 15 seconds before navigation
          setTimeout(() => {
            const newWindow = window.open(response.data.url, '_self');
            if (!newWindow) {
              console.error("Unable to navigate to the URL.");
            }
            setLoading(false); // Hide loader after the URL is opened
          }, 15000); // Wait for 15 seconds before opening the URL
        } else {
          toast.error("No URL returned from the second API call.");
          setLoading(false); // Hide loader if no URL is returned
        }
      } catch (error) {
        if (error.response) {
          toast.error(`Error: ${error.response.data.message}`);
        } else {
          toast.error("An error occurred while fetching user details.");
        }
        setLoading(false); // Hide loader if there is an error
      }
    };

    if (CusID && planId) {
      sendTravelData();
    } else {
      toast.error("Invalid parameters provided.");
      setLoading(false);
    }
  }, [CusID, planId]);

  return (
    <div className="p-4">
      <ToastContainer position='top-center' />

      {/* Show loader when loading is true */}
      {loading ? (
        <div className="flex flex-col items-center justify-center min-h-screen">
          <div className="w-12 h-12 border-4 border-t-blue-500 border-gray-300 rounded-full animate-spin"></div>
          <p className="mt-4 text-gray-600">Processing... Please wait.</p>
        </div>
      ) : (
        userDetails && (
          <div className="bg-gray-100 p-6 rounded shadow-md">
            <h2 className="text-xl font-semibold mb-4">User Information</h2>
            <ul className="list-disc ml-6">
              <li>Email: {userDetails.email}</li>
              <li>Username: {userDetails.username}</li>
              <li>Last Name: {userDetails.Cust_Lastname}</li>
              <li>Address: {userDetails.Cust_Address}</li>
              <li>City: {userDetails.Cust_City}</li>
              <li>Pincode: {userDetails.Cust_Pincode}</li>
            </ul>
          </div>
        )
      )}
    </div>
  );
};

export default Travelredirect;
