import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube, faTwitter, faInstagram, faFacebookF, faLinkedinIn, faWhatsapp, faTelegram, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faArrowUp, faPaperPlane, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { toast, Toaster } from 'react-hot-toast';
import { SERVER_URL } from "../../config";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

export default function Footer() {

    const navigate = useNavigate();

    const user = useSelector((state) => state.user);
    const emailID = user ? user[0].email : '';

    const [email, setEmails] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const loadingToastId = toast.loading('Subscribing...');
        try {
            const response = await axios.post(`${SERVER_URL}/api/newsletter_subscribe`, {
                email: email,
            });

            const { new_members, errors } = response.data;
            toast.dismiss(loadingToastId);

            if (new_members.length > 0) {
                setStatusMessage('Email successfully subscribed!');
                setErrorMessage('');
                toast.success('Email successfully subscribed!');
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            } else if (errors.length > 0) {

                console.log(errors)
                const errorMessage = errors[0].error_code === "ERROR_CONTACT_EXISTS" ? 'Already Subscribed' : 'Failed to subscribe'
                setStatusMessage('');
                setErrorMessage(errorMessage);
                toast.error(errorMessage);
            }
        } catch (error) {
            toast.dismiss(loadingToastId);
            setStatusMessage('');
            setErrorMessage('Failed to subscribe email.');
            toast.error('Failed to subscribe email.');
        }
    };

    const handleRedirect = () => {
        if (emailID) {
            navigate("/User/dashboard");
        } else {
            navigate("/login");
        }
    };

    const [isTerms, setTerms] = useState(false);

    const handleTerms = () => {
        setTerms(!isTerms)
    }


    return (
        <div className='font-poppins'>
            {/* <Toaster /> */}
            <div className='w-full bg-black '>
                <div className='container mx-auto '>
                    <div className='md:flex md:flex-row flex-col items-center justify-evenly lg:px-8 p-4'>
                        <div className='flex items-center justify-center lg:justify-start  lg:w-1/3 md:w-1/5 w-full h-full md:border  md:border-r-white md:border-transparent border-none'>
                            <div className='my-4'>
                                <img src='/assets/footerLogo.png' className='w-[50%] mx-auto md:w-full brightness-150'></img>
                            </div>
                        </div>
                        <div className='lg:w-2/3 w-full flex sm:flex-row flex-col md:justify-between  md:ml-4 ml-0'>
                            <div className=' w-full block md:mt-0 mt-4 lg:text-start text-center'>
                                <ul className='flex justify-start flex-col'>
                                    <li><a href='/' className='text-white'><span className='text-white/20 pr-2'>|</span>Home</a></li>
                                    <li><a href='/about' className='text-white'><span className='text-white/20 pr-2'>|</span>About us</a></li>
                                    <li><a href='/Exclusive' className='text-white'><span className='text-white/20 pr-2'>|</span>MBC Exclusives</a></li>
                                </ul>
                            </div>
                            <div className=' w-full block  lg:text-start text-center  md:mt-0 mt-4'>
                                <ul className='flex justify-start flex-col'>
                                    <li><a href='/Membership' className='text-white'><span className='text-white/20 pr-2'>|</span>Membership plan</a></li>
                                    <li><a href='/FAQ' className='text-white'><span className='text-white/20 pr-2'>|</span>Knowledge Base</a></li>
                                </ul>
                            </div>
                            <div className=' w-full block  lg:text-start text-center  md:mt-0 mt-4'>
                                <ul className='flex justify-start flex-col lg:pl-5'>
                                    <li><a href='/Signin' className='text-white cursor-pointer'><span className='text-white/20 pr-2'>|</span>Signup</a></li>
                                    <li><a href='/Login' className='text-white cursor-pointer'><span className='text-white/20 pr-2'>|</span>Login</a></li>
                                </ul>
                            </div>
                            <div className=' w-full  lg:text-start text-center md:mt-0 mt-4'>
                                <h4 className='font-bold text-lg text-white mb-2'>Get in touch</h4>
                                <ul className='flex items-center gap-2 justify-between text-[#ee5d57] mt-3'>
                                    <li><a href='https://www.instagram.com/mybusinessclub_official/' className='px-3 py-2 border border-white/10 rounded-full'><FontAwesomeIcon icon={faInstagram} /></a></li>
                                    <li><a href='https://www.facebook.com/profile.php?id=61559105265026' className='px-3.5 py-2 border border-white/10 rounded-full'><FontAwesomeIcon icon={faFacebookF} /></a></li>
                                    <li><a href='https://rb.gy/a1nli1' className='px-3 py-2 border border-white/10 rounded-full'><FontAwesomeIcon icon={faXTwitter} /></a></li>
                                    <li><a href='https://rb.gy/7x2p32' className='px-3 py-2 border border-white/10 rounded-full'><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                                    <li><a href='https://www.youtube.com/@MyBusinessClub_Official' className='px-2.5 py-2 border border-white/10 rounded-full'><FontAwesomeIcon icon={faYoutube} /></a></li>
                                    <li><a target='_blank' href='https://chat.whatsapp.com/J5TXU53NYoh9U2nrC4IEia' className='px-3 text-lg py-2 border border-white/10 rounded-full'><FontAwesomeIcon icon={faWhatsapp} /></a></li>
                                    <li><a target='_blank' href='https://t.me/userguidembcbot' className='px-3 py-2 border border-white/10 rounded-full'><FontAwesomeIcon icon={faTelegram} /></a></li>
                                </ul>
                                <form onSubmit={handleSubmit}>
                                    <div className='flex bg-white rounded-lg mt-3'>
                                        <input
                                            type='text'
                                            value={email}
                                            onChange={(e) => setEmails(e.target.value)}
                                            className='w-[90%] bg-transparent outline-none p-2 text-black text-xs placeholder:text-gray-500'
                                            placeholder='Enter your Email address'
                                        />
                                        <button type='submit' className='w-[10%] px-1'>
                                            <FontAwesomeIcon icon={faPaperPlane} className='text-[#ee5d57]' />
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-black text-center lg:text-start border-t text-white border-white/20 px-[5%] py-5 flex flex-col lg:flex-row gap-3 md:gap-0  justify-between'>
                <div className='my-auto'>© 2024 My Business Club</div>
                <div className='flex flex-col md:flex-row justify-end gap-3 md:gap-5 cursor-pointer' onClick={handleTerms}>
                    <p className=' my-auto'>Terms of Service</p>
                    <p className='my-auto'>Privacy Policy</p>
                    <div><a className='px-3.5 py-2 text-[#ee5d57] border border-white/10 rounded-full'><FontAwesomeIcon icon={faArrowUp} /></a></div>
                </div>
            </div>
            {isTerms && (
                <div className='fixed z-50 text-black inset-0 top-0 font-poppins flex justify-center items-center bg-black/50 backdrop-blur-sm'>
                    <div className='w-[90%] md:w-[75%] bg-white rounded-2xl' data-aos='fade-in'>
                        <div className='px-5 py-3 border-b border-black/20 flex justify-between items-center'>
                            <p className='font-semibold'>Terms and Conditions</p>
                            <FontAwesomeIcon className='cursor-pointer' onClick={handleTerms} icon={faXmarkCircle} />
                        </div>
                        <div className='px-5 py-3 overflow-hidden overflow-y-auto max-h-[400px]'>
                            <h1 className='text-2xl font-bold text-center mb-4'>General Disclaimer</h1>
                            <p className='text-gray-700 mt-2'>The information provided herein is intended solely for informational purposes and does not constitute financial, investment, legal, or any other form of advice. My Business Club operates as a private membership club dedicated to educational purposes. Membership benefits include access to a suite of services, including educational content, podcasts, and travel benefits, tailored to varying levels of membership.</p>
                            <p className='text-gray-700 mt-2'>It is important to note that My Business Club does not guarantee any return on investment. We do not offer investment opportunities or promise financial gains. Rather, our focus is on providing valuable educational resources and services to our members.</p>
                            <p className='text-gray-700 mt-2'>Membership in My Business Club involves the exchange of membership fees for access to the benefits and services. We strive to provide high-quality educational content and experiences.</p>
                            <p className='text-gray-700 mt-2'>We encourage individuals to make informed decisions based on their own due diligence and to seek advice from qualified professionals if needed. By continuing your participation in My Business Club, you acknowledge and accept the terms outlined in this disclaimer.</p>
                            <h1 className='text-2xl font-bold text-center mb-4'>Income Disclaimer</h1>
                            <p className='text-gray-700 mt-2'>We strive to accurately represent My Business Club, its membership benefits, and the educational content it offers, as well as the My Business Club referral rewards plan and its potential for benefits. My Business Club accelerates the accumulation of knowledge and access to resources within, through member referrals.</p>
                            <p className='text-gray-700 mt-2'>There are no guarantees regarding benefits or rewards from the My Business Club membership opportunity. The basic membership reward plan offers members anticipated benefits based on the value of membership acquired.</p>
                            <p className='text-gray-700 mt-2'>Any mention of potential benefits made in this presentation solely describes the My Business Club membership plan itself. Our Service Agreement and regulatory requirements strictly prohibit members or presenters from making income claims, as such claims may be considered enticement.</p>
                            <p className='text-gray-700 mt-2'>The examples provided may showcase exceptional results that may not be typical for the average member and are not intended to represent or guarantee that anyone will achieve the same or similar results. The benefits, achievements, or rank levels are dependent upon individual members' skills, ambition, commitment, expertise, activity, and demographic factors. As with any educational programme or membership club, individual results may vary.</p>
                            <p className='text-gray-700 mt-2'>It is crucial that the use of our information, products, and services is based on your own due diligence. By engaging with My Business Club, you agree that our company is not liable for any success or failure of your educational pursuits or business endeavours directly or indirectly related to the acquisition and use of our membership, information, referral plan, products, or services.</p>
                            <div className='mt-4'>
                                <h1 className="text-2xl font-bold text-center mb-4">Terms and Conditions</h1>
                                <div className="space-y-4">
                                    <div>
                                        <h2 className="text-lg font-semibold">1. Application and Agreement</h2>
                                        <ul className="list-disc list-inside mt-2 space-y-2">
                                            <li>Applicants must be 18 years or older to apply.</li>
                                            <li>By applying, you authorize My Business Club to store and use your data.</li>
                                            <li>The agreement is valid from the sign-up date and memberships are valid for 1 year from the payment date.</li>
                                            <li>My Business Club reserves the right to accept or decline applications without reason.</li>
                                        </ul>
                                    </div>

                                    <div>
                                        <h2 className="text-lg font-semibold">2. Fees and Payments</h2>
                                        <ul className="list-disc list-inside mt-2 space-y-2">
                                            <li>There is no registration or processing fee.</li>
                                            <li>You agree to pay for the chosen plan, which is considered payment for memberships listed on the company website/application.</li>
                                            <li>All memberships must be purchased via crypto payments.</li>
                                            <li>Memberships can be added or removed at the company's discretion.</li>
                                        </ul>
                                    </div>

                                    <div>
                                        <h2 className="text-lg font-semibold">3. Distributorship and Memberships</h2>
                                        <ul className="list-disc list-inside mt-2 space-y-2">
                                            <li>Upon registration, you become an independent distributor and are entitled to earn referral rewards.</li>
                                            <li>Purchasing memberships is optional. You can choose to buy memberships on day one at full cost and unlock the relevant benefits.</li>
                                            <li>Membership refunds are not available under any circumstances.</li>
                                        </ul>
                                    </div>

                                    <div>
                                        <h2 className="text-lg font-semibold">4. Membership Restrictions</h2>
                                        <ul className="list-disc list-inside mt-2 space-y-2">
                                            <li>Independent distributors cannot make promises to potential distributors.</li>
                                            <li>The company is not liable for promises made by other distributors.</li>
                                            <li>Certain membership benefits may have their own country restrictions; please check the local laws in your region before activating those benefits.</li>
                                        </ul>
                                    </div>

                                    <div>
                                        <h2 className="text-lg font-semibold">5. Distributor Rights and Responsibilities</h2>
                                        <ul className="list-disc list-inside mt-2 space-y-2">
                                            <li>You are an independent distributor, not an employee or agent of the company.</li>
                                            <li>Membership sales are governed by the business plan and company policies.</li>
                                            <li>You acknowledge that the payment is for membership purchase and not a refundable deposit.</li>
                                            <li>Your income depends on the sales value made under you according to the business plan.</li>
                                            <li>Becoming a distributor is optional. You may also be required to make personal membership purchases to qualify for rewards.</li>
                                        </ul>
                                    </div>

                                    <div>
                                        <h2 className="text-lg font-semibold">6. Incentive Program</h2>
                                        <ul className="list-disc list-inside mt-2 space-y-2">
                                            <li>Every membership package purchased by a new distributor in your team adds an incentive to your account.</li>
                                            <li>You agree to the company's incentive programme details.</li>
                                            <li>You will be promoted through the distributor ranks based on performance.</li>
                                            <li>You will receive a copy of the entire scheme outlining your rights and obligations.</li>
                                        </ul>
                                    </div>

                                    <div>
                                        <h2 className="text-lg font-semibold">7. Reward Payments</h2>
                                        <ul className="list-disc list-inside mt-2 space-y-2">
                                            <li>Your rewards will be available to withdraw anytime in your back-office wallet.</li>
                                            <li>Rewards are paid by smart contract.</li>
                                            <li>The company's decision on reward distribution is final.</li>
                                            <li>Members are responsible for their taxes in their own countries.</li>
                                        </ul>
                                    </div>

                                    <div>
                                        <h2 className="text-lg font-semibold">8. Company Rights</h2>
                                        <ul className="list-disc list-inside mt-2 space-y-2">
                                            <li>My Business Club can modify its business plan, rules, memberships, and income plan at any time.</li>
                                            <li>Distributors are responsible for keeping up to date with these changes.</li>
                                        </ul>
                                    </div>

                                    <div>
                                        <h2 className="text-lg font-semibold">9. Distributor Conduct</h2>
                                        <ul className="list-disc list-inside mt-2 space-y-2">
                                            <li>Distributors must perform their duties in a way that benefits the company and complies with all laws.</li>
                                            <li>The company is not liable for claims arising from unauthorised presentations by distributors.</li>
                                            <li>Distributors are responsible for indemnifying the company against third-party damages.</li>
                                            <li>Distributors must refrain from using unauthorised marketing materials.</li>
                                        </ul>
                                    </div>

                                    <div>
                                        <h2 className="text-lg font-semibold">10. Termination</h2>
                                        <ul className="list-disc list-inside mt-2 space-y-2">
                                            <li>In case of a distributor's death, the distributorship and benefits can be transferred to a nominated beneficiary as per the member’s Will.</li>
                                            <li>My Business Club can cancel distributorships for various reasons, including violation of company policies.</li>
                                            <li>Upline and downline distributors will continue to receive their earned rewards even after termination.</li>
                                            <li>Distributors who cease to be distributors cannot be readmitted for at least six months. Readmission is at the company's discretion.</li>
                                            <li>Distributors are not entitled to benefits during termination.</li>
                                            <li>Upon resignation, distributors must submit a written notice and are not entitled to refunds.</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h2 className="text-lg font-semibold">11. Distributor Policies</h2>
                                        <ul className="list-disc list-inside mt-2 space-y-2">
                                            <li>Distributors cannot introduce or sell unauthorised products or services through the network.</li>
                                            <li>Reselling company memberships below the fixed price is prohibited.</li>
                                            <li>Repackaging of company memberships is not allowed.</li>
                                            <li>Only official company materials can be used for marketing.</li>
                                            <li>Promotional materials cannot be reproduced or misused.</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h2 className="text-lg font-semibold">12. Website Disclaimer</h2>
                                        <ul className="list-disc list-inside mt-2 space-y-2">
                                            <li>Distributors are solely responsible for any claims related to membership sales, licensing, marketing, and taxation.</li>
                                            <li>The company is not liable for problems arising from website connectivity, technical issues, or any outdated information on websites.</li>
                                            <li>Distributors indemnify My Business Club against all claims made by third parties related to their conduct.</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h2 className="text-lg font-semibold">13. Distributor Training and Requirements</h2>
                                        <ul className="list-disc list-inside mt-2 space-y-2">
                                            <li>Distributors are encouraged to regularly attend on and offline business seminars and trainings to understand the terms, business nature, and memberships.</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h2 className="text-lg font-semibold">14. Cross-Sponsoring and Recruiting</h2>
                                        <ul className="list-disc list-inside mt-2 space-y-2">
                                            <li>Cross-sponsoring and cross-recruiting are strictly prohibited.</li>
                                            <li>Cross-sponsoring involves influencing a distributor to join under a different downline.</li>
                                            <li>Cross-recruiting involves recruiting a distributor for another network marketing company that is not directly enrolled by you in MBC.</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h2 className="text-lg font-semibold">15. Use of Trademarks</h2>
                                        <ul className="list-disc list-inside mt-2 space-y-2">
                                            <li>Distributors cannot use My Business Club trademarks except to promote the company.</li>
                                            <li>Displaying the company name or logo outside the official materials is strictly forbidden.</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h2 className="text-lg font-semibold">16. Password Security</h2>
                                        <ul className="list-disc list-inside mt-2 space-y-2">
                                            <li>Distributors are responsible for keeping their passwords confidential.</li>
                                            <li>The company is not liable for any consequences of password misuse.</li>
                                        </ul>
                                    </div>

                                    <div>
                                        <h2 className="text-lg font-semibold">17. Amendments</h2>
                                        <ul className="list-disc list-inside mt-2 space-y-2">
                                            <li>All written amendments to this agreement must be approved by the company to be valid.</li>
                                        </ul>
                                    </div>

                                    <div>
                                        <h2 className="text-lg font-semibold">18. Force Majeure</h2>
                                        <ul className="list-disc list-inside mt-2 space-y-2">
                                            <li>Neither party is liable for delays caused by circumstances beyond their control.</li>
                                        </ul>
                                    </div>

                                    <div>
                                        <h2 className="text-lg font-semibold">19. Dispute Resolution</h2>
                                        <ul className="list-disc list-inside mt-2 space-y-2">
                                            <li>Any disputes will be addressed through a written complaint to the company.</li>
                                            <li>The company may refer the matter to a sole arbitrator chosen by them.</li>
                                            <li>Multiple accounts are not allowed, each acc must have own KYC.</li>
                                            <li>Anyone found to be gaming the system risks termination of accounts and income</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}