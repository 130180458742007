import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faGear, faXmark } from '@fortawesome/free-solid-svg-icons';
import '../../Style/UserDashboard.css';
import { SERVER_URL, MORALIS_API_KEY, TOKEN_ADDRESS, PROVIDER_URL, MBCPayOut_ContractAddress, MBCToken_ContractAddress } from '../../../config';
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { ethers } from "ethers";
import { MBCPayOut_abi, token_abi } from '../../../ABI';
import BigNumber from "bignumber.js";
import {
  useWeb3ModalAccount, useWeb3ModalProvider
} from "@web3modal/ethers5/react";
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Subscription() {

  const user = useSelector((state) => state.user);
  const email = user[0].email;
  const CusID = user[0].CusID;

  const [DepositAddress, setDepositAddress] = useState(null)
  const [IncomeAddress, setIncomeAddress] = useState(null)
  const [transactions, setTransactions] = useState([]);
  const [DepositBalance, setDepositBalance] = useState(0);
  const [IncomeBalance, setIncomeBalance] = useState(0);
  const [Popup, setPopup] = useState(false);
  const [enteredId, setEnteredId] = useState(CusID);

  const [lastSub, setLastSub] = useState('');
  const [DayDiff, setDayDiff] = useState(0);

  useEffect(() => {
    if (lastSub !== '') {
      const lastSubDate = new Date(lastSub);
      const currentDate = new Date();
      const daysDifference = Math.ceil(Math.abs(currentDate - lastSubDate) / (1000 * 60 * 60 * 24));
      setDayDiff(daysDifference)
    }
  }, [lastSub])

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider, walletProviderType } = useWeb3ModalProvider();

  const fetchTokenValues = async (address) => {
    try {
      const response = await fetch(`https://deep-index.moralis.io/api/v2.2/wallets/${address}/tokens?chain=bsc`, {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'X-API-Key': MORALIS_API_KEY
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      const targetToken = data.result.find(token => token.token_address.toLowerCase() === TOKEN_ADDRESS.toLowerCase());
      console.log("balance", data)

      if (targetToken) {
        const balance = parseFloat(targetToken.balance) / (10 ** targetToken.decimals);

        return balance;
      } else {
        return 0;
      }

    } catch (error) {
      console.error('Error fetching token balance:', error);
      return null;
    }
  };

  useEffect(() => {

    if (DepositAddress !== null) {
      fetchTokenValues(DepositAddress).then((balance) => setDepositBalance(balance));
    }
    if (IncomeAddress !== null) {
      getUserDetails()
    }
  }, [DepositAddress, IncomeAddress]);

  const [DepositedAmount, setDepositedAmount] = useState(0);
  const [PlanIDList, setPlanIDList] = useState([]);


  const getUserDetails = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(PROVIDER_URL);
      let contract = new ethers.Contract(MBCPayOut_ContractAddress, MBCPayOut_abi, provider);
      let getDetails = await contract.getuserDetails(IncomeAddress);

      const userDetailsArray = [
        getDetails[0],
        getDetails[1],
        Number(getDetails[2].toString()),
        Number(getDetails[3].toString()),
        Number(getDetails[4].toString()),
        Number(getDetails[5].toString()),
        getDetails[6].toString(),
        getDetails[7],
        Number(getDetails[8].toString()),
        getDetails[9].toString(),
        getDetails[10].toString(),
        Number(getDetails[11].toString()),
      ];
      const timestamp = Number(getDetails[11].toString());
      const date = new Date(timestamp * 1000);

      console.log('User Details Array:', userDetailsArray);
      setIncomeBalance(new BigNumber(getDetails[10] / 10 ** 18).toFixed());
      setDepositedAmount(new BigNumber(getDetails[6] / 10 ** 18).toFixed());
      setLastSub(date.toISOString())
    } catch (err) {
      console.error('Error fetching user details:', err);
    }
  };

  const fetchAddress = async () => {
    const response = await axios.get(`${SERVER_URL}/api/fetchAddress?CusID=${CusID}`);
    if (response.data.status === true) {
      setIncomeAddress(response.data.address.income_wallet)
    }
  }

  useEffect(() => {
    const fetchSubscriptionHistory = async () => {
      try {
        let response = await axios.get(`${SERVER_URL}/api/fetchSubsHistory?CusID=${CusID}`);
        if (response.data.status === true) {
          setTransactions(response.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (CusID) {
      fetchSubscriptionHistory();
      fetchAddress()
    }
  }, [CusID]);

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = transactions.slice(indexOfFirstEntity, indexOfLastEntity);
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage(prevPage => {
      const totalPages = Math.ceil(transactions.length / entitiesPerPage);
      return Math.min(prevPage + 1, totalPages);
    });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(transactions.length / entitiesPerPage);
  const maxPageNumbersToShow = 5;
  const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
  const pageNumbers = [];

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const [Subscriptionplan, setSubscriptionplan] = useState(false);
  const [Info, setInfo] = useState(false);

  const [selectedSubscription, setSelectedSubscription] = useState({
    type: '',
    amount: 0,
    no: 0
  });

  const [selectedOption, setSelectedOption] = useState('Active Self ID');

  const handleSubscriptionplan = (type, amount, no) => {

    if (address === undefined) {
      toast.error("Please connect the Wallet");
      return false;
    }

    if (IncomeAddress !== address) {
      toast.error("Please connect the correct Wallet");
      return false;
    }

    setSubscriptionplan(true);
    setSelectedSubscription({ type, amount, no });
  };

  const handleclose = () => {
    setSubscriptionplan(false);
    setSelectedSubscription({
      type: '',
      amount: 0,
      no: 0
    });
    setInfo(false);
  };

  const handleInfo = () => {
    // if (DepositBalance < selectedSubscription.amount) {
    // toast.error("Insufficient Balance");
    // return false;
    // }
    setInfo(!Info);
  };

  const handleSelectChange = (e) => {
    const option = e.target.value;
    setSelectedOption(option);

    if (option === 'Active Self ID') {
      setEnteredId(CusID);
    } else {
      setEnteredId('');
    }
  };

  const handleInputChange = (e) => {
    setEnteredId(e.target.value);
  };

  const confirmSubscription = async () => {
    try {
      setPopup(true);

      if (!isConnected) {
        toast.error("Please Connect Wallet First");
        setPopup(false);
        return false;
      }

      // Fetch Sponser Data
      const sponserResponse = await axios.post(`${SERVER_URL}/api/getSponserData`, {
        CusID: Number(CusID),
      });

      let referrerAddress = '0x0000000000000000000000000000000000000000';
      if (sponserResponse.data.status === true) {
        referrerAddress = sponserResponse.data.referrerAddress;
      }

      if (selectedSubscription.amount <= Number(DepositedAmount)) {
        toast.error("Please purchase a higher package");
        setPopup(false);
        return false;
      }

      const lastSubDate = new Date(lastSub);
      const currentDate = new Date();
      const daysDifference = Math.ceil(Math.abs(currentDate - lastSubDate) / (1000 * 60 * 60 * 24));

      let TransferAmount = daysDifference > 90
        ? ethers.utils.parseUnits(selectedSubscription.amount.toString(), 18)
        : ethers.utils.parseUnits((selectedSubscription.amount - Number(DepositedAmount)).toString(), 18);

      console.log(TransferAmount / 10 ** 18);

      const plan_id = selectedSubscription.no;

      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();

      const TokenContract = new ethers.Contract(MBCPayOut_ContractAddress, MBCPayOut_abi, signer);
      const User_TokenContract = new ethers.Contract(MBCToken_ContractAddress, token_abi, signer);

      const getBalance = await User_TokenContract.balanceOf(address);
      let userBalance = new BigNumber(getBalance / 10 ** 18).toFixed();
      let planAmount = new BigNumber(TransferAmount / 10 ** 18).toFixed();

      console.log(typeof userBalance, typeof planAmount);

      console.log(new BigNumber(getBalance / 10 ** 18).toFixed(), new BigNumber(TransferAmount / 10 ** 18).toFixed());

      if (Number(userBalance) < Number(planAmount)) {
        toast.error("Insufficient USDT balance");
        setPopup(false);
        return false;
      }

      const gasPrice = await provider.getGasPrice();
      const ApprovegasLimit = await User_TokenContract.estimateGas.approve(MBCPayOut_ContractAddress, TransferAmount);

      const getDetails = await TokenContract.getuserDetails(address);
      const useraddress = getDetails[0];

      let hash;
      if (useraddress === '0x0000000000000000000000000000000000000000') {
        const RegisterApprove = await User_TokenContract.approve(MBCPayOut_ContractAddress, TransferAmount, {
          gasPrice: ethers.utils.parseUnits("5", "gwei"),
          gasLimit: 5000000
        });
        await RegisterApprove.wait();
        const Register = await TokenContract.register(referrerAddress, plan_id, {
          gasPrice: ethers.utils.parseUnits("5", "gwei"),
          gasLimit: 5000000
        });
        await Register.wait();
        hash = Register.hash;

      } else {

        const ApproveToken = await User_TokenContract.approve(MBCPayOut_ContractAddress, TransferAmount, {
          gasPrice: ethers.utils.parseUnits(ethers.utils.formatUnits(gasPrice, "gwei"), "gwei"),
          gasLimit: ApprovegasLimit
        });
        await ApproveToken.wait();

        const Subscribe = await TokenContract.upgradePlan(plan_id, {
          gasPrice: ethers.utils.parseUnits("5", "gwei"),
          gasLimit: 500000
        });
        await Subscribe.wait();

        hash = Subscribe.hash;
      }

      const responsePromise = axios.post(`${SERVER_URL}/api/subscription`, {
        plan: selectedSubscription.type,
        plan_id: selectedSubscription.no,
        amount: TransferAmount / 10 ** 18,
        CusID: Number(enteredId),
        from_address: address,
        subscribed_by: CusID,
        hash: hash,
        email: email
      });

      await toast.promise(
        responsePromise,
        {
          loading: "Transaction is in progress...",
          success: (response) => {
            console.log(response, 'response');
            return response.data.status === true
              ? "Subscribed Successfully !!"
              : response.data.message || "Error during Subscription.";
          },
          error: (error) => {
            console.error("Error during Subscription:", error);

            if (error.response) {
              const statusCode = error.response.status;
              const errorMessage = error.response.data.message || "Error during Subscription.";

              switch (statusCode) {
                case 400: return "Bad Request: Please check your input data.";
                case 401: return "Unauthorized: Please check your credentials.";
                case 403: return "Forbidden: You do not have access to this resource.";
                case 404: return "Not Found: The subscription endpoint is incorrect.";
                case 500: return "Internal Server Error: Please try again later.";
                default: return errorMessage;
              }
            } else if (error.request) {
              return "No response from server: Please check your network connection.";
            } else {
              return `Error: ${error.message}`;
            }
          }
        },
        {
          success: { duration: 5000 },
          error: { duration: 5000 }
        }
      );
      setTimeout(() => {
        window.location.reload()
      }, 4000);
      setPopup(false);
    } catch (error) {
      setPopup(false);
      console.error("An unexpected error occurred:", error);
      toast.error("An unexpected error occurred.");
      setTimeout(() => {
        window.location.reload()
      }, 4000);
    }
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  }

  const openImageInNewTab = (hash) => {
    if (hash) {
      window.open(`https://bscscan.com/tx/${hash}`, '_blank');
    }
  };

  const [Team, setTeam] = useState(0)
  const [Subscription, setSubscription] = useState(0)
  const [history, setHistory] = useState([])
  const [SubscribeHistory, setSubscribeHistory] = useState([])

  const getRecords = async () => {
    try {
      await axios.get(`${SERVER_URL}/api/getTransRecords?id=${user[0].CusID}`).then((res) => {
        if (res.data.status == true) {
          setHistory(res.data.history)
          setTeam(res.data.TeamCount)
          setSubscription(res.data.Subscription)
        } else {
          setHistory([])
          setTeam(0)
          setSubscription('0.00')
        }
      })
    } catch (err) {
      console.log(err)
      setHistory([])
    }
  }

  useEffect(() => {
    getRecords()
    getSubRecords()
  }, [user])

  const getSubRecords = async () => {
    try {
      await axios.get(`${SERVER_URL}/api/getSubscribeHistory?id=${user[0].CusID}`).then((res) => {
        // if(res.data.status == true){
        // setTransactions(res.data.history)
        // }else{
        // setTransactions([])
        // }
      })
    } catch (err) {
      console.log(err)
      setSubscribeHistory([])
    }
  }

  function getPurchasedPlans() {
    const planList = [7, 50, 200, 600, 1500, 3000, 5000, 10000];

    const result = [];
    let remaining = DepositedAmount;

    const sortedPlans = [...planList].sort((a, b) => b - a);

    for (let plan of sortedPlans) {
      if (remaining >= plan) {
        result.push(plan);
        remaining -= plan;
      }
    }
    if (remaining === 0) {
      setPlanIDList(result);
      return result;
    } else {
      return `Invalid total ${DepositedAmount} with the available plans`;
    }
  }

  useEffect(() => {
    if (DepositedAmount > 0) {
      getPurchasedPlans();
    }
  }, [DepositedAmount])

  return (
    <div>
      {/* <Toaster position='top-center' /> */}
      <div data-aos="fadeIn" data-aos-duration='2000'>
        <div className='md:pl-5 py-5 md:pr-10 font-poppins'>
          {Subscriptionplan ? (
            <div className='mt-10 border rounded-md px-2 md:px-10 py-5 md:py-7'>
              <div>
                <div className='flex justify-between'>
                  <h1 className='text-xl font-bold'>Subscribe Now</h1>
                  <div className='cursor-pointer' onClick={handleclose}>
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                </div>
                <div className='w-[95%] md:w-[60%] lg:w-[40%] p-5 mx-auto mt-3'>
                  <p className='text-sm font-bold'>Information</p>
                  <div className='border border-white/30 rounded-md py-2 px-3 mt-2'>
                    <p className='text-white/75 text-xs 2xl:text-sm'>Subscription Plan</p>
                    <p className='font-semibold 2xl:text-lg'>{selectedSubscription.type}</p>
                  </div>
                  <div className='border border-white/30 rounded-md py-2 px-3 mt-2'>
                    <p className='text-white/75 text-xs 2xl:text-sm'>Subscription Amount ($)</p>
                    <p className='font-semibold 2xl:text-lg'>{DayDiff <= 90 ? selectedSubscription.amount - DepositedAmount : selectedSubscription.amount}</p>
                  </div>
                  <div className='border border-white/30 rounded-md py-2 px-3 mt-2'>
                    <p className='text-white/75 text-xs 2xl:text-sm'>Wallet Address</p>
                    <p className='font-semibold 2xl:text-lg'>{IncomeAddress ? (IncomeAddress).slice(0, 20) + '...' : ''}</p>
                  </div>
                  {/* <div className='border border-white/30 rounded-md py-2 px-3 mt-2'>
                    <p className='text-white/75 text-xs 2xl:text-sm'>Choose Activation Type</p>
                    <select
                      className='bg-transparent w-full outline-none'
                      value={selectedOption}
                      onChange={handleSelectChange}
                    >
                      <option className='text-black'>Active Self ID</option>
                      <option className='text-black'>Active Others ID</option>
                    </select>
                  </div>
                  {selectedOption === 'Active Others ID' && (
                    <input
                      className='w-[100%] bg-transparent border border-white/30 rounded-md h-[58px] py-2 px-3 mt-2 placeholder:text-white'
                      placeholder='Enter UserID'
                      value={enteredId}
                      onChange={handleInputChange}
                    />
                  )} */}
                  <div className='flex justify-center mt-2'>
                    <button className=' bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-sm font-semibold px-5 py-2 rounded-md ' onClick={() => { confirmSubscription(); }}>
                      Buy Now
                      {/* Send OTP */}
                    </button>
                  </div>
                </div>
                {/* {Info ? (
                
                ) : (
                  <div>
                    <p className='font-bold text-center mt-5'>Please Select Wallet</p>
                    <div
                      className='w-[90%] md:w-[50%] lg:w-[30%] border rounded-xl shadow-md shadow-black/30 text-center p-5 mx-auto mt-3 cursor-pointer duration-500 hover:scale-110'
                      onClick={handleInfo}
                    >
                      <h1 className='tracking-wider uppercase '>Deposit Wallet</h1>
                      <p className='uppercase font-bold  text-2xl my-1'>$ {Number(DepositBalance).toFixed(2)}</p>
                      <p className='text-sm'>Analytics for Deposit Balance</p>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          ) : (
            <div className='mt-10 border rounded-md px-10 py-7 grid grid-cols-2 lg:grid-cols-4 gap-5'>
              {[
                { type: 'AFFILIATE', no: 1, amount: 7, img: '/assets/firstPlan.png' },
                { type: 'MEMBER', no: 2, amount: 50, img: '/assets/Starter.png' },
                { type: 'SILVER', no: 3, amount: 200, img: '/assets/StarterPackages_01.png' },
                { type: 'GOLD', no: 4, amount: 600, img: '/assets/StarterPackages_02.png' },
                { type: 'PLATINUM', no: 5, amount: 1500, img: '/assets/StarterPackages_03.png' },
                { type: 'EXECUTIVE', no: 6, amount: 3000, img: '/assets/StarterPackages_04.png' },
                { type: 'AMBASSADOR', no: 7, amount: 5000, img: '/assets/StarterPackages_05.png' },
                { type: 'PRESIDENT', no: 8, amount: 10000, img: '/assets/StarterPackages_06.png' },
                // { type: 'Subscription - 7', no: 7, amount: 5000, img: '/assets/StarterPackages_07.png' },
                // { type: 'Subscription - 8', no: 8, amount: 10000, img: '/assets/StarterPackages_08.png' },
              ].map((plan, index) => (
                <div className="flip-card mx-auto " key={index}>
                  <div className="flip-card-inner rounded-xl">
                    <div className="flip-card-front rounded-xl border flex items-center justify-center">
                      <img src={plan.img} className='' alt={plan.type} />
                    </div>
                    <div className="flip-card-back py-3 px-3 border grid content-end rounded-xl">
                      <h1 className='font-bold text-3xl'>${plan.amount}</h1>
                      <p className='mt-2 text-sm'>{plan.type}</p>
                      <button
                        className={`${PlanIDList.includes(plan.amount) || plan.amount <= DepositedAmount ? 'cursor-not-allowed' : ''} mt-5 bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-sm font-semibold w-full py-2 rounded-md`}
                        onClick={() => handleSubscriptionplan(plan.type, plan.amount, plan.no)}
                        disabled={PlanIDList.includes(plan.amount) || plan.amount <= DepositedAmount}
                      >
                        {PlanIDList.includes(plan.amount) || plan.amount <= DepositedAmount ? 'Subscribed' : 'Subscribe Now'}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className='rounded-2xl mt-10'>
            <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between pb-5'>
              <div className=''>
                <p className='my-auto font-bold text-xl '>Subscription History</p>
              </div>
              <div className='flex items-center'>
                <p className='text-end text-sm px-3 my-auto'>Show no of entity
                  <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                    <option className='text-black' value="5">5</option>
                    <option className='text-black' value="10">10</option>
                    <option className='text-black' value="100">100</option>
                    <option className='text-black' value="200">200</option>
                  </select>
                </p>
              </div>
            </div>
            <div className='mb-5 border rounded-xl overflow-hidden overflow-x-auto'>
              <table className='w-full text-center '>
                <thead className='uppercase font-bold text-sm border-b py-3'>
                  <tr>
                    <th className='py-3'>SI.No</th>
                    <th>Cust ID</th>
                    <th>Hash</th>
                    <th>Date</th>
                    <th>Package Amount</th>
                    <th>Package</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody className='divide-y'>
                  {currentEntities.map((entity, index) => (
                    <tr key={index} className='h-16 text-sm md:text-base text-center rounded-md font-bold'>
                      <td className='px-5 md:px-0 '>{(currentPage - 1) * entitiesPerPage + index + 1}</td>
                      <td className='px-5 md:px-0'>{entity.CustID ?? entity.CusID}</td>
                      <td className='px-5 md:px-0 cursor-pointer' onClick={() => openImageInNewTab(entity.TrID ?? entity.trx_hash)}>{(entity.TrID ?? entity.trx_hash).slice(0, 7)}...</td>
                      <td className='px-5 md:px-0'>{formatDate(entity.dot ?? entity.createdAt)}</td>
                      <td className='px-5 md:px-0'>$ {Number(entity.amount).toFixed(0)}</td>
                      <td className='px-5 md:px-0'>{entity.plan}</td>
                      <td className='px-5 md:px-0 text-green-500'>SUCCESS</td>
                    </tr>
                  ))}
                  {Array.from({ length: emptyRowCount }).map((_, index) => (
                    <tr key={`empty-${index}`} className='h-16'>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='mt-2 flex justify-between text-xs px-3'>
              <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
              <div className='my-auto flex justify-center items-center gap-1'>
                <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                {pageNumbers.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                    onClick={() => handlePageClick(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                ))}
                <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
              </div>
              <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      {Popup && (
        <div className='fixed z-30 inset-0 w-screen left-0 top-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20'>
          <div className='flex items-center gap-2 text-xl font-semibold'>
            <FontAwesomeIcon icon={faGear} className='fa-spin' />
            <p>Loading...</p>
          </div>
        </div>
      )}
    </div>
  )
}