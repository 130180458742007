import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { Navigate, useLocation } from "react-router-dom";
import Aos from 'aos';
import 'aos/dist/aos.css';
import toast, { Toaster } from "react-hot-toast";
import { SERVER_URL } from "../../../config";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Plans from "./Plans";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
Aos.init();


const Membership = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [showTooltip2, setShowTooltip2] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    const hasVisited = sessionStorage.getItem('hasVisitedmembershipPage');
    if (!hasVisited) {
      setShowTooltip2(true);

      sessionStorage.setItem('hasVisitedmembershipPage', 'true');
    }
  }, []);

  const closeTooltip2 = () => {
    setShowTooltip2(false);
  };

  const user = useSelector((state) => state.user);
  const emailId = user && user[0] ? user[0].email : null;


  useEffect(() => {
    if (location.state?.scrollToSection) {
      const section = document.getElementById(location.state.scrollToSection);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.state]);


  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [mobilenumber, setMobileNumber] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();


    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]+$/;
    const nameRegex = /^[a-zA-Z\s]+$/;

    if (!fullname.trim()) {
      toast.error('Full name is required.');
      return;
    } else if (!nameRegex.test(fullname)) {
      toast.error('Full name should only contain letters and spaces.');
      return;
    }

    if (!email.trim() || !emailRegex.test(email)) {
      toast.error('A valid email address is required.');
      return;
    }

    if (!mobilenumber.trim() || !mobileRegex.test(mobilenumber)) {
      toast.error('Mobile number should contain only numbers.');
      return;
    }

    try {
      const response = await axios.post(`${SERVER_URL}/api/interests`, {
        fullname,
        email,
        mobilenumber
      });

      if (response.data.success) {
        // toast.success('Thanks for showing interest!');
        setShowPopUp(true)
        setFullname('');
        setEmail('');
        setMobileNumber('');
      } else {
        toast.error('Failed to save details.');
        setShowPopUp(false)
      }

    } catch (error) {
      console.error('There was an error!', error);
      toast.error('Error saving details.');
      setShowPopUp(false)
    }
  };

  const handleRedirect = () => {
    if (emailId) {
      navigate("/User/dashboard?tab=Subscription");
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="text-white font-poppins">
      <div className='fixed inset-0 z-0'>
        <img src='/assets/NewMBCbg.png' className='h-full w-full object-cover object-top'></img>
      </div>
      <div className="relative z-10">
        <Header />
        <Toaster />
        <Plans />
        <form onSubmit={handleSubmit}>
          <div id="sec-3" className="sec-3  bg-cover min-h-screen font-poppins text-white ">
            <div className="xl:w-[75%] w-[90%]  py-10 mx-auto flex justify-center items-center min-h-screen">
              <div className="flex md:flex-row flex-col justify-center gap-10 border-2 rounded-lg lg:p-10 p-5" data-aos="fade-up" data-aos-duration="1000">
                <div className="lg:w-[45%] sm:w-[40%]">
                  <h1 className="text-3xl md:text-4xl lg:text-5xl  font-bold capitalize text-shadow-custom" data-aos="fade-up" data-aos-duration="1250">Interest form </h1>
                  <div className="md:w-[80%]">
                    <div className="w-32 h-0.5 bg-white mt-1" data-aos="fade-right" data-aos-duration="1750"></div>
                    <p className="font-semibold mt-5" data-aos="zoom-in" data-aos-duration="1500">Submit your interest form, and we will reach out to you with more information.</p>
                  </div>
                </div>


                <div className="lg:w-[55%] sm:w-[60%] grid content-center relative" data-aos="fade-up" data-aos-duration="1000">
                  <div className={`bg-white rounded-lg p-5  ${showPopUp === true ? 'opacity-25' : ''} `}>
                    <div className="lg:w-[60%] mx-auto">
                      <p className="text-black font-semibold leading-6 text-center">Are you ready to elevate your business to new heights?</p>
                    </div>
                    <div className="md:w-[85%] mx-auto mt-10 md:mt-0">
                      <div>
                        <label className="text-black/40 font-bold">Fullname</label>
                        <input type="text"
                          value={fullname}
                          onChange={(e) => setFullname(e.target.value)}
                          className="bg-[#EFEFEF] mt-2 rounded-xl px-5 py-2 focus:outline-none font-semibold text-[#F23985] w-full"
                          disabled={showPopUp}
                        />
                      </div>

                      <div className="mt-3">
                        <label className="text-black/40 font-bold">Email address</label>
                        <input type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="bg-[#EFEFEF] mt-2 rounded-xl px-5 py-2 focus:outline-none font-semibold text-[#F23985] w-full"
                          disabled={showPopUp}
                        />
                      </div>

                      <div className="mt-3">
                        <label className="text-black/40 font-bold">Mobile number</label>
                        <input
                          type="tel"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          minLength={7}
                          maxLength={15}
                          placeholder="Enter Phone number"
                          value={mobilenumber}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^[0-9]*$/.test(value)) {
                              setMobileNumber(value);
                            }
                          }}
                          className="bg-[#EFEFEF] mt-2 rounded-xl px-5 py-2 focus:outline-none font-semibold text-[#F23985] w-full"
                          disabled={showPopUp}
                        />
                      </div>

                      <div className="mt-5">
                        <button type="submit" disabled={showPopUp}
                          className="w-full rounded-2xl py-3 shadow-lg shadow-black/30 bg-gradient-to-r from-[#F23985] to-[#FB9236] font-semibold">
                          Save & Next
                        </button>
                      </div>
                    </div>
                  </div>
                  {showPopUp === true ?
                    <div className="absolute w-[90%] top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] ">
                      <div className="w-[100%] bg-white rounded-xl shadow-md p-5 flex gap-5 items-center" data-aos='fade-in' data-aos-duration='1000'>
                        <div><FontAwesomeIcon icon={faCheckCircle} className="text-[#00ff00]" /></div>
                        <div className="text-black text-sm 2xl:text-base text-center">Thank you for your interest, we will get in touch with you shortly</div>
                      </div>
                    </div>
                    : ''}
                </div>
              </div>
            </div>
          </div>
        </form>
        <Footer />
      </div>
    </div>
  )
}

export default Membership