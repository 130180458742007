import React, { useState } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { faChevronRight, faMagnifyingGlass, faMinus, faPlus, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Aos from 'aos';
import 'aos/dist/aos.css';

Aos.init();

const FAQ = () => {

  const [videoplay, setvideoplay] = useState(false);
  const [videoplay2, setvideoplay2] = useState(false);
  const [videoplay3, setvideoplay3] = useState(false);
  const [videoplay4, setvideoplay4] = useState(false);
  const [videoplay5, setvideoplay5] = useState(false);

  const handleVideoplay = () => {
    setvideoplay(!videoplay);
  };

  const handleVideoplay2 = () => {
    setvideoplay2(!videoplay2)
  }
  const handleVideoplay3 = () => {
    setvideoplay3(!videoplay3)
  }
  const handleVideoplay4 = () => {
    setvideoplay4(!videoplay4)
  }
  const handleVideoplay5 = () => {
    setvideoplay5(!videoplay5)
  }

  const faq = [
    {
      question: "Why should I join My Business Club?",
      answer: "<strong>My Business Club (MBC)</strong> is all about <strong>empowering entrepreneurs</strong> with <strong>real-time rewards.</strong> Our membership offers <strong>instant payouts</strong> through <strong>Smart Contract Pay,</strong> meaning you get paid the moment a sale or referral is made<strong>—no delays, no hassle.</strong> If you value speed, transparency, and growth, MBC is the place for you.",
    },
    {
      question: "How do real-time payments work?",
      answer:
        "Our <strong>smart contracts</strong> handle all transactions automatically. This means as soon as you close a deal or make a referral, you get paid <strong>immediately</strong>. It’s fast, secure, and completely transparent, giving you instant access to your earnings.",
    },
    {
      question: "What is the MBC Membership Plan?",
      answer:
        "The MBC Membership Plan is a tiered membership program that allows you to join the MBC community, earn commissions, and access exclusive benefits. Starting with a free membership, you can upgrade to higher levels like Silver, Gold, or even President to unlock more perks and opportunities.",
    },
    {
      question: "How do I sign up for the MBC Membership?",
      answer:
        'Signing up is easy! Simply click the "Sign Up Now" button on our website and follow the registration process. You can start with a free membership and upgrade whenever you’re ready to take advantage of more benefits.',
    },
    {
      question: "What are the benefits of upgrading my membership?",
      answer:
        "Each upgrade unlocks new perks such as increased earning potential, access to exclusive tools, personalized support, and investment opportunities like Real-World Asset NFTs. The higher your membership tier, the more rewards you gain.",
    },
    {
      question: "Can I upgrade my membership at any time?",
      answer:
        "Yes! You can upgrade your membership at any time by paying the difference between your current plan and the next level within 90 days. This flexibility allows you to grow at your own pace.",
    },
    {
      question: "What happens if I don’t upgrade within 90 days?",
      answer:
        "If you don’t upgrade within the 90-day window, you can still upgrade later, but you’ll need to pay the full price of the desired membership tier. Upgrading within 90 days helps you take advantage of a seamless transition."
    },
    {
      question: "How much commission do I earn from referrals?",
      answer:
        "All members, including free members, earn a <strong>10% commission</strong> on MBC referrals. This means that every new member you bring into the MBC community can help you earn extra income."
    },
    {
      question: "What is the Ainstein License, and how does it benefit me?",
      answer:
        "The Ainstein License gives you access to advanced tools for just <strong>$100,</strong> with <strong>50% of the fee converted to Commissionable Volume (CV).</strong> It’s an excellent way to enhance your earning potential while using top-notch resources."
    },
    {
      question: "Are there any hidden fees or charges?",
      answer:
        "No, there are no hidden fees. The membership fees are transparent and clearly outlined, depending on the tier you choose. You only pay the difference when upgrading within the 90-day period."
    },
    {
      question: "What kind of support is available for members?",
      answer:
        "MBC members receive tailored support based on their membership level. Our team is here to assist you with any questions and provide guidance to help you maximize your membership benefits."
    },
    {
      question: "How do I contact customer support if I have more questions?",
      answer:
        "You can reach out to our customer support team through the contact form on our website. We’re here to help you with any questions or concerns."
    }
  ];

  const [expandedIndex, setExpandedIndex] = useState(-1);

  const toggleExpand = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(-1); // Collapse if already expanded
    } else {
      setExpandedIndex(index); // Expand the selected item
    }
  };

  const [searchValue, setSearchValue] = useState("");
  const tutorialCards = [
    {
      title: "Login & Sign-Up",
      description: "Learn how to create an account and log in securely.",
      onClick: () => handleVideoplay2(),
    },
    {
      title: "Connect Wallet",
      description: "A complete guide to connecting your wallet to access all platform features.",
      onClick: () => handleVideoplay3(),
    },
    {
      title: "Subscription",
      description: "Step-by-step instructions on subscribing to your desired membership plan.",
      onClick: () => handleVideoplay4(),
    },
    {
      title: "Withdrawal",
      description: "Learn how to withdraw your earnings quickly and safely.",
      onClick: () => handleVideoplay5(),
    },
    {
      title: "Product Video",
      description: "How to get started with one of our partnered products, Skilleareum, and how to use them effectively.",
      onClick: () => handleVideoplay(),
    },
  ];

  // Filter the cards based on search input
  const filteredCards = tutorialCards.filter((card) =>
    card.title.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div>
      <div
        className="  sec-1 bg-black bg-cover font-poppins text-white"
        style={{ backgroundImage: "url(../assets/NewMBCbg.png)" }}
      >
        <Header />
        <div className="pt-28 2xl:pt-0 2xl:min-h-screen grid content-center">
          <h1 className="text-3xl text-center font-bold text-shadow-custom" data-aos="fade-down">
            Hello! Welcome To MBC
          </h1>
          <p className="mt-5 font-semibold w-[90%] md:w-[75%] mx-auto text-center">
            Explore our step-by-step video guides to help you get started with ease. Whether you’re new to Skilleareum or an experienced user, these tutorials will ensure a smooth experience.
          </p>
          <div className="mt-5 bg-white px-3 py-1 2xl:py-2 rounded-md flex items-center w-[90%] md:w-[70%] lg:w-[35%] mx-auto">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="text-gray-400" />
            <input
              className="w-[100%] outline-none py-1 placeholder:text-gray-400 text-[#E43875] font-semibold pl-2"
              placeholder="Search Tutorials"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <button className="bg-gradient-to-r from-[#E43875] to-[#F98736] rounded-full px-5 py-1 font-bold text-sm shadow-md">
              Search
            </button>
          </div>
          <div className="mt-10 grid gap-5 w-[90%] md:w-[80%] mx-auto">
            {filteredCards.length > 0 ? (
              <div className="flex flex-col md:flex-wrap md:flex-row justify-center gap-5">
                {filteredCards.map((card, index) => (
                  <div
                    key={index}
                    className="w-[100%] md:w-[48%] lg:w-[32%] border-2 duration-300 hover:shadow-md hover:-translate-y-2 hover:bg-gradient-to-b from-[#E43875] to-[#F98736] rounded-2xl text-white p-5"
                  >
                    <h1 className="text-center text-xl font-semibold">{card.title}</h1>
                    <p className="text-sm 2xl:text-base mt-2 leading-5">
                      {card.description}{" "}
                      <span className="cursor-pointer font-bold underline" onClick={card.onClick}>
                        Watch Video
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex justify-center">
                <p className="bg-white text-center mt-5 text-black font-semibold px-5 py-2 rounded-md">No tutorials found.</p>
              </div>
            )}
          </div>
        </div>
        {videoplay2 && (
          <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
            <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
              <div className='flex justify-end'>
                <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay2} icon={faXmarkCircle} />
              </div>
              <iframe
                width="100%"
                height="500"
                //src="https://www.youtube.com/embed/OXWnxQOsZUU?autoplay=1&controls=0&modestbranding=1&rel=0&showinfo=0&iv_load_policy=3"
                src="/assets/Intro/MBC updated registration.mp4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ borderRadius: '15px', overflow: 'hidden' }}
              ></iframe>
            </div>
          </div>
        )}
        {videoplay3 && (
          <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
            <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
              <div className='flex justify-end'>
                <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay3} icon={faXmarkCircle} />
              </div>
              <iframe
                width="100%"
                height="500"
                //src="https://www.youtube.com/embed/OXWnxQOsZUU?autoplay=1&controls=0&modestbranding=1&rel=0&showinfo=0&iv_load_policy=3"
                src="/assets/Intro/wallet connect.mp4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ borderRadius: '15px', overflow: 'hidden' }}
              ></iframe>
            </div>
          </div>
        )}
        {videoplay4 && (
          <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
            <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
              <div className='flex justify-end'>
                <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay4} icon={faXmarkCircle} />
              </div>
              <iframe
                width="100%"
                height="500"
                //src="https://www.youtube.com/embed/OXWnxQOsZUU?autoplay=1&controls=0&modestbranding=1&rel=0&showinfo=0&iv_load_policy=3"
                src="/assets/Intro/MBC subscription updated.mp4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ borderRadius: '15px', overflow: 'hidden' }}
              ></iframe>
            </div>
          </div>
        )}
        {videoplay5 && (
          <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
            <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
              <div className='flex justify-end'>
                <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay5} icon={faXmarkCircle} />
              </div>
              <iframe
                width="100%"
                height="500"
                //src="https://www.youtube.com/embed/OXWnxQOsZUU?autoplay=1&controls=0&modestbranding=1&rel=0&showinfo=0&iv_load_policy=3"
                src="/assets/Intro/Withdrawal MBC.mp4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ borderRadius: '15px', overflow: 'hidden' }}
              ></iframe>
            </div>
          </div>
        )}
        {videoplay && (
          <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
            <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
              <div className='flex justify-end'>
                <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay} icon={faXmarkCircle} />
              </div>
              <iframe
                width="100%"
                height="500"
                //src="https://www.youtube.com/embed/OXWnxQOsZUU?autoplay=1&controls=0&modestbranding=1&rel=0&showinfo=0&iv_load_policy=3"
                src="/assets/Intro/skilleareum MBC.mp4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ borderRadius: '15px', overflow: 'hidden' }}
              ></iframe>
            </div>
          </div>
        )}
        <div className="pt-10">
          <h1 className="text-2xl md:text-4xl lg:text-5xl text-center font-bold  capitalize text-shadow-custom" data-aos="fade-up" data-aos-duration="">faq’s</h1>
          <p className="font-semibold text-lg text-center" data-aos="zoom-in" data-aos-duration="">Questions? Look here.</p>
        </div>
        <div className="lg:w-[55%] w-[90%] mx-auto mt-10 pb-10" data-aos="fade-up" data-aos-duration="1000">
          {faq.slice(0, 12).map((data, index) => (
            <div
              key={index}
              onClick={() => toggleExpand(index)}
              className={`text-white border rounded-md bg-gradient-to-r from-[#E43875] to-[#F98736]  cursor-pointer mt-2 duration-500 transform transition ease-in-out ${expandedIndex === index ? "h-full" : "h-full"
                }`}
            >
              <div className="flex ">
                <div className="w-[90%] p-3">
                  <h1 className="font-semibold text-lg py-2">{data.question}</h1>
                </div>
                <div className="w-[10%] flex items-center justify-center">
                  <button className="font-bold">
                    {expandedIndex === index ? (
                      <FontAwesomeIcon
                        icon={faMinus}
                        className={`transform transition-transform duration-500`}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faPlus}
                        className={`transform transition-transform duration-500`}
                      />
                    )}
                  </button>
                </div>
              </div>
              <div
                className={`duration-300 px-5 bg-white ease-in-out overflow-hidden ${expandedIndex === index
                  ? "max-h-[250px]  md:max-h-[140px] py-5"
                  : "max-h-0"
                  }`}
              >
                <p
                  dangerouslySetInnerHTML={{ __html: data.answer }}
                  className="text-[#595959] font-medium duration-500"
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FAQ;