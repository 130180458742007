import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { SERVER_URL } from '../../config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


export default function Skilleareumai() {

  const user = useSelector((state) => state.user);
  const email = user ? user[0].email : '';
  const CusID = user ? user[0].CusID : '';

  const handleClick = () => {
    if (!CusID) {
      console.error("CusID not available");
      toast.error('Please Login to use MBC Exclusive');
      return;
    }
    try {
      window.open(`https://skilleareum.ai/redirect?CusID=${CusID}`, "_blank");
    } catch (error) {
      console.error("Error handling click event:", error);
    }
  };

  const [videoplay, setvideoplay] = useState(false);
  const [videoplay1, setvideoplay1] = useState(false);
  const [videoplay2, setvideoplay2] = useState(false);

  const handleVideoplay = () => {
    setvideoplay(!videoplay)
  }
  const handleVideoplay1 = () => {
    setvideoplay1(!videoplay1)
  }
  const handleVideoplay2 = () => {
    setvideoplay2(!videoplay2)
  }

  const [showTooltip, setShowTooltip] = useState(true);
  const closeTooltip = () => {
    setShowTooltip(false);
  };


  useEffect(() => {
    const hasVisited = sessionStorage.getItem('SkilleareumVideo1');
    if (!hasVisited) {
      setShowTooltip(true);
      sessionStorage.setItem('SkilleareumVideo1', 'true');
    }
  }, []);

  const navigate = useNavigate(); // Hook for navigation

  const handletravel = async () => {
  
    try {
      if (!CusID) {
          console.error("CusID not available");
          toast.error('Please Login to use MBC Exclusive');
          return;
      }
      const response = await axios.post(`${SERVER_URL}/api/getuserdetails`, { CusID });
      console.log(response,'response')
      const { message, CusID: receivedCusID, planId } = response.data;

      if (message === 'Please upgrade your subscription.') {
        toast.error('Please upgrade your plan.');
      } else if (message === 'Limit exceeded') {
        toast.error('Limit exceeded. Please upgrade your subscription.');

      }
      else if (message === 'Navigate now.') {
        // Navigate to the /travelredirect page with CusID and planId as URL parameters
        navigate(`/travelredirect?CusID=${receivedCusID}&planId=${planId}`);
      }
    } catch (error) {
      console.log(error,'error')
      if (error.response) {
        console.error('Error fetching user details:', error.response.data);
      }
      toast.error('No Subscription Found for your Wallet..Please Upgrade your Plan !!! .');
    }
  };

  const handletravelmod = async () => {

    try {
      if (!CusID) {
          console.error("CusID not available");
          toast.error('Please Login to use MBC Exclusive');
          return;
      }
      const response = await axios.post(`${SERVER_URL}/api/getuserdetailsmod`, { CusID });
      const { message, CusID: receivedCusID, planId } = response.data;
      if (message === 'Please upgrade your subscription.') {
        toast.warning('Please upgrade your plan.');
      } else if (message === 'Limit exceeded') {
        toast.warning('Limit exceeded. Please upgrade your subscription.');

      }
      else if (message === 'Navigate now.') {
        // Navigate to the /travelredirect page with CusID and planId as URL parameters
        navigate(`/travelredirectmod?CusID=${receivedCusID}&planId=${planId}`);
      }
    } catch (error) {
      if (error.response) {
        console.error('Error fetching user details:', error.response.data);
      }
      toast.error('No Subscription Found for your Wallet..Please Upgrade your Plan !!! .');
    }
  };

  return (
    <div>
      <ToastContainer position='top-center' />

      <div className='relative h-[88vh] mt-5 flex flex-col'>
        <div className=' h-full flex items-center justify-center'>

          <div className=' w-[100%] mx-auto flex flex-col md:flex-row justify-around gap-5 md:gap-0' >
            <div className='md:w-[30%]'>
              <img src='/assets/SKILL 30 SEPT.jpg' className='mx-auto border rounded-xl' data-aos="zoom-in" data-aos-duration="1250"></img>
            </div>
            <div className='md:w-[60%] grid content-center'>
              <div className='flex md:flex-row flex-col justify-start' data-aos="fade-right"
                data-aos-duration="1000">
                <div className='flex items-center gap-4'>
                  <p className='font-bold  text-3xl uppercase cursor-pointer hover:underline' data-aos="fade-right" data-aos-duration="1000" onClick={handleClick} >Skilleareum.ai</p>
                  <FontAwesomeIcon className='cursor-pointer w-10 h-10 ' onClick={handleVideoplay} icon={faCirclePlay} />
                </div>
                {showTooltip && (
                  <div className="relative md:mt-0 mt-3" data-aos="fade-right" data-aos-duration="1000">
                    <div className="absolute left-3 top-9 transform -translate-y-1/2">
                      <div
                        style={{ clipPath: 'polygon(100% 50%, 0% 0%, 0% 100%)' }}
                        className="bg-white/20 w-5 h-5 rotate-180"
                      ></div>
                    </div>

                    <div className="relative md:pl-8 md:-top-0 bottom-0 -pl-10">
                      <div className=" z-50 p-4  bg-white/20 backdrop-blur-lg text-white rounded-md shadow-lg">
                        <button className="text-white text-lg font-bold rotate-45 absolute top-0 right-2" onClick={closeTooltip}>
                          +
                        </button>
                        <p className="text-xs 2xl:text-sm text-center font-semibold">
                          Having doubts about connecting in Skilleareum? Check out this video!
                        </p>
                      </div>
                    </div>
                  </div>
                )
                }
              </div>
              {videoplay && (
                <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
                  <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
                    <div className='flex justify-end'>
                      <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay} icon={faXmarkCircle} />
                    </div>
                    <iframe
                      width="100%"
                      height="500"
                      src="/assets/Intro/skilleareum MBC.mp4"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{ borderRadius: '15px', overflow: 'hidden' }}
                    ></iframe>
                  </div>
                </div>
              )}
              <ul className='mt-3'>
                <li data-aos="fade-right" data-aos-duration="1250">1. The World’s First Learn & Earn Social-EduFi DAO Platform.</li>
                <li data-aos="fade-right" data-aos-duration="1500">2. Token Incentives: Earn tokens by completing educational courses.</li>
                <li data-aos="fade-right" data-aos-duration="1750">3. Global Learning Ecosystem: Participate in a decentralized, unbiased learning environment.  </li>
                <li data-aos="fade-right" data-aos-duration="2000">4. Instantaneous Rewards: Tokenize every learning achievement.</li>
              </ul>

              <div className='mt-5 font-bold' data-aos="fade-right" data-aos-duration="1250">
                How to download Telegram?
              </div>

              <div className="button-container mt-3 flex justify-start gap-4" data-aos="fade-right" data-aos-duration="1250">
                <button
                  className="toggle-button border-2 border-white text-white py-3 px-6 text-lg font-medium rounded-md hover:bg-white hover:text-black transition-all duration-300"
                  onClick={handleVideoplay2}>
                  Windows
                </button>
                <button
                  className="toggle-button border-2 border-white text-white py-3 px-6 text-lg font-medium rounded-md hover:bg-white hover:text-black transition-all duration-300"
                  onClick={handleVideoplay1}>
                  Mac OS
                </button>
              </div>
              {videoplay1 && (
                <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
                  <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
                    <div className='flex justify-end'>
                      <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay1} icon={faXmarkCircle} />
                    </div>
                    <iframe
                      width="100%"
                      height="500"
                      src="/assets/Intro/Mac tele login comp.mp4"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{ borderRadius: '15px', overflow: 'hidden' }}
                    ></iframe>
                  </div>
                </div>
              )}
              {videoplay2 && (
                <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
                  <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
                    <div className='flex justify-end'>
                      <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay2} icon={faXmarkCircle} />
                    </div>
                    <iframe
                      width="100%"
                      height="500"
                      src="/assets/Intro/windows tele login comp.mp4"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{ borderRadius: '15px', overflow: 'hidden' }}
                    ></iframe>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className=' h-full flex items-center justify-center'>

          <div className=' w-[100%] mx-auto flex flex-col md:flex-row justify-around gap-5 md:gap-0' >
            <div className='md:w-[30%]'>
              <img src='/assets/SKILL 30 SEPT.jpg' className='mx-auto border rounded-xl' data-aos="zoom-in" data-aos-duration="1250"></img>
            </div>
            <div className='md:w-[60%] grid content-center'>
              <div className='flex md:flex-row flex-col justify-start' data-aos="fade-right"
                data-aos-duration="1000">
                <div className='flex items-center gap-4'>
                  <p className='font-bold  text-3xl uppercase cursor-pointer hover:underline' data-aos="fade-right" data-aos-duration="1000" onClick={handletravel} >travel partner (purple engine)</p>
                  <FontAwesomeIcon className='cursor-pointer w-10 h-10 ' onClick={handleVideoplay} icon={faCirclePlay} />
                </div>
                {showTooltip && (
                  <div className="relative md:mt-0 mt-3" data-aos="fade-right" data-aos-duration="1000">
                    <div className="absolute left-3 top-9 transform -translate-y-1/2">
                      <div
                        style={{ clipPath: 'polygon(100% 50%, 0% 0%, 0% 100%)' }}
                        className="bg-white/20 w-5 h-5 rotate-180"
                      ></div>
                    </div>

                    <div className="relative md:pl-8 md:-top-0 bottom-0 -pl-10">
                      <div className=" z-50 p-4  bg-white/20 backdrop-blur-lg text-white rounded-md shadow-lg">
                        <button className="text-white text-lg font-bold rotate-45 absolute top-0 right-2" onClick={closeTooltip}>
                          +
                        </button>
                        <p className="text-xs 2xl:text-sm text-center font-semibold">
                          Having doubts about connecting in Skilleareum? Check out this video!
                        </p>
                      </div>
                    </div>
                  </div>
                )
                }
              </div>
              {videoplay && (
                <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
                  <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
                    <div className='flex justify-end'>
                      <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay} icon={faXmarkCircle} />
                    </div>
                    <iframe
                      width="100%"
                      height="500"
                      src="/assets/Intro/skilleareum MBC.mp4"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{ borderRadius: '15px', overflow: 'hidden' }}
                    ></iframe>
                  </div>
                </div>
              )}
              <ul className='mt-3'>
                <li data-aos="fade-right" data-aos-duration="1250">1. The World’s First Learn & Earn Social-EduFi DAO Platform.</li>
                <li data-aos="fade-right" data-aos-duration="1500">2. Token Incentives: Earn tokens by completing educational courses.</li>
                <li data-aos="fade-right" data-aos-duration="1750">3. Global Learning Ecosystem: Participate in a decentralized, unbiased learning environment.  </li>
                <li data-aos="fade-right" data-aos-duration="2000">4. Instantaneous Rewards: Tokenize every learning achievement.</li>
              </ul>

              <div className='mt-5 font-bold' data-aos="fade-right" data-aos-duration="1250">
                How to download Telegram?
              </div>

              <div className="button-container mt-3 flex justify-start gap-4" data-aos="fade-right" data-aos-duration="1250">
                <button
                  className="toggle-button border-2 border-white text-white py-3 px-6 text-lg font-medium rounded-md hover:bg-white hover:text-black transition-all duration-300"
                  onClick={handleVideoplay2}>
                  Windows
                </button>
                <button
                  className="toggle-button border-2 border-white text-white py-3 px-6 text-lg font-medium rounded-md hover:bg-white hover:text-black transition-all duration-300"
                  onClick={handleVideoplay1}>
                  Mac OS
                </button>
              </div>
              {videoplay1 && (
                <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
                  <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
                    <div className='flex justify-end'>
                      <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay1} icon={faXmarkCircle} />
                    </div>
                    <iframe
                      width="100%"
                      height="500"
                      src="/assets/Intro/Mac tele login comp.mp4"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{ borderRadius: '15px', overflow: 'hidden' }}
                    ></iframe>
                  </div>
                </div>
              )}
              {videoplay2 && (
                <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
                  <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
                    <div className='flex justify-end'>
                      <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay2} icon={faXmarkCircle} />
                    </div>
                    <iframe
                      width="100%"
                      height="500"
                      src="/assets/Intro/windows tele login comp.mp4"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{ borderRadius: '15px', overflow: 'hidden' }}
                    ></iframe>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className=' h-full flex items-center justify-center'>

          <div className=' w-[100%] mx-auto flex flex-col md:flex-row justify-around gap-5 md:gap-0' >
            <div className='md:w-[30%]'>
              <img src='/assets/SKILL 30 SEPT.jpg' className='mx-auto border rounded-xl' data-aos="zoom-in" data-aos-duration="1250"></img>
            </div>
            <div className='md:w-[60%] grid content-center'>
              <div className='flex md:flex-row flex-col justify-start' data-aos="fade-right"
                data-aos-duration="1000">
                <div className='flex items-center gap-4'>
                  <p className='font-bold  text-3xl uppercase cursor-pointer hover:underline' data-aos="fade-right" data-aos-duration="1000" onClick={handletravelmod} >travel partner (mod engine)</p>
                  <FontAwesomeIcon className='cursor-pointer w-10 h-10 ' onClick={handleVideoplay} icon={faCirclePlay} />
                </div>
                {showTooltip && (
                  <div className="relative md:mt-0 mt-3" data-aos="fade-right" data-aos-duration="1000">
                    <div className="absolute left-3 top-9 transform -translate-y-1/2">
                      <div
                        style={{ clipPath: 'polygon(100% 50%, 0% 0%, 0% 100%)' }}
                        className="bg-white/20 w-5 h-5 rotate-180"
                      ></div>
                    </div>

                    <div className="relative md:pl-8 md:-top-0 bottom-0 -pl-10">
                      <div className=" z-50 p-4  bg-white/20 backdrop-blur-lg text-white rounded-md shadow-lg">
                        <button className="text-white text-lg font-bold rotate-45 absolute top-0 right-2" onClick={closeTooltip}>
                          +
                        </button>
                        <p className="text-xs 2xl:text-sm text-center font-semibold">
                          Having doubts about connecting in Skilleareum? Check out this video!
                        </p>
                      </div>
                    </div>
                  </div>
                )
                }
              </div>
              {videoplay && (
                <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
                  <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
                    <div className='flex justify-end'>
                      <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay} icon={faXmarkCircle} />
                    </div>
                    <iframe
                      width="100%"
                      height="500"
                      src="/assets/Intro/skilleareum MBC.mp4"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{ borderRadius: '15px', overflow: 'hidden' }}
                    ></iframe>
                  </div>
                </div>
              )}
              <ul className='mt-3'>
                <li data-aos="fade-right" data-aos-duration="1250">1. The World’s First Learn & Earn Social-EduFi DAO Platform.</li>
                <li data-aos="fade-right" data-aos-duration="1500">2. Token Incentives: Earn tokens by completing educational courses.</li>
                <li data-aos="fade-right" data-aos-duration="1750">3. Global Learning Ecosystem: Participate in a decentralized, unbiased learning environment.  </li>
                <li data-aos="fade-right" data-aos-duration="2000">4. Instantaneous Rewards: Tokenize every learning achievement.</li>
              </ul>

              <div className='mt-5 font-bold' data-aos="fade-right" data-aos-duration="1250">
                How to download Telegram?
              </div>

              <div className="button-container mt-3 flex justify-start gap-4" data-aos="fade-right" data-aos-duration="1250">
                <button
                  className="toggle-button border-2 border-white text-white py-3 px-6 text-lg font-medium rounded-md hover:bg-white hover:text-black transition-all duration-300"
                  onClick={handleVideoplay2}>
                  Windows
                </button>
                <button
                  className="toggle-button border-2 border-white text-white py-3 px-6 text-lg font-medium rounded-md hover:bg-white hover:text-black transition-all duration-300"
                  onClick={handleVideoplay1}>
                  Mac OS
                </button>
              </div>
              {videoplay1 && (
                <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
                  <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
                    <div className='flex justify-end'>
                      <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay1} icon={faXmarkCircle} />
                    </div>
                    <iframe
                      width="100%"
                      height="500"
                      src="/assets/Intro/Mac tele login comp.mp4"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{ borderRadius: '15px', overflow: 'hidden' }}
                    ></iframe>
                  </div>
                </div>
              )}
              {videoplay2 && (
                <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
                  <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
                    <div className='flex justify-end'>
                      <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay2} icon={faXmarkCircle} />
                    </div>
                    <iframe
                      width="100%"
                      height="500"
                      src="/assets/Intro/windows tele login comp.mp4"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{ borderRadius: '15px', overflow: 'hidden' }}
                    ></iframe>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
