import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";

import Header from './Components/Layout/Header';
import Footer from './Components/Layout/Footer';
import Login from './Components/Auth/Login';
import Signup from './Components/Auth/Signup';
import ForgetPassword from './Components/Auth/forget_password';
import VerifyEmail from './Components/Auth/verifyEmail';

import Home from './Components/Pages/Home/Home';
import About from './Components/Pages/About';
import Membership from './Components/Pages/Membership/Membership';
import Exclusive from './Components/Pages/Exclusive';
import FAQ from './Components/Pages/FAQ';
import Dashboard from './Components/Dashboard/Dashboard';
import ReferralLink from './Components/Pages/ReferralLink';

import Signin from './User/Auth/Signin';
import Register from './User/Auth/Register';
import MyDashboard from './User/Dashboard/MyDashboard';

import AdminLogin from './Admin/Auth/AdminLogin';
import AdminDashboard from './Admin/Dashboard/AdminDashboard';

import Home2 from './Components/Pages/Home/Home2';
import Businessclub from './Components/Pages/Businessclub';
import AddWhitelist from './User/adminwhitelist';

import Maintence from './Components/Pages/Maintence';
import Travelredirect from './Components/Pages/Travelredirect';
import Travelredirectmod from './Components/Pages/Travelredirectmod';

function Routers() {

    const admin_email = useSelector((state) => state.admin_email);
    const user = useSelector((state) => state.user);
    const user_email = user[0] ? user[0].email : '';

    return (
        <Routes>

            {/* <Route path="*" element={<Maintence />} /> */}
         
            <Route path="/Login" element={<Login />} />
            <Route path="/Signup" element={<Signup />} />
            <Route path="/Forget-Password" element={<ForgetPassword />} />
            <Route path="/VerifyEmail" element={<VerifyEmail />} />

            <Route path="/Header" element={<Header />} />
            <Route path="/Footer" element={<Footer />} />

            <Route path="/" element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/Membership" element={<Membership />} />
            <Route path="/Exclusive" element={<Exclusive />} />
            <Route path="/FAQ" element={<FAQ />} />
            <Route path="/ReferralLink" element={<ReferralLink />} />

            <Route path="/Dashboard" element={<Dashboard />} />

            <Route path="/User/" element={<Signin />} />
            <Route path="/User/register" element={<Register />} />
            <Route path="/User/dashboard" element={user_email ? <MyDashboard /> : <Navigate to="/Login" />} />

            <Route path="/Admin" element={<AdminLogin />} />
            <Route path="/Admin/dashboard" element={admin_email ? <AdminDashboard /> : <Navigate to="/Admin" />} />

            <Route path="/Admin/AddWhitelist" element={<AddWhitelist />} />

            <Route path="/Landing2" element={<Home2 />} />
            <Route path="/BC" element={<Businessclub />} /> 
            <Route path="/travelredirect" element={<Travelredirect />} /> 
            <Route path="/travelredirectmod" element={<Travelredirectmod />} /> 


        </Routes>
    );
}

export default Routers;
