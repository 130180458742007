// import React, { useState } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

// export default function DirectIncome() {
//   const tableEntries = [

//   ];
//   const [currentPage, setCurrentPage] = useState(1);
//   const [entitiesPerPage, setEntitiesPerPage] = useState(5);

//   // Determine the current entries to display
//   const indexOfLastEntity = currentPage * entitiesPerPage;
//   const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
//   const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
//   const emptyRowCount = entitiesPerPage - currentEntities.length;

//   // Handle previous and next page clicks
//   const handlePrevClick = () => {
//     setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
//   };

//   const handleNextClick = () => {
//     setCurrentPage(prevPage => {
//       const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
//       return Math.min(prevPage + 1, totalPages);
//     });
//   };

//   // Handle page number click
//   const handlePageClick = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   // Handle dropdown change for entities per page
//   const handleDropdownChange = (e) => {
//     setEntitiesPerPage(parseInt(e.target.value));
//     setCurrentPage(1);
//   };

//   const openImageInNewTab = (entity) => {
//     window.open(entity.doc, '_blank');
//   };

//   // Calculate pagination details
//   const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
//   const maxPageNumbersToShow = 5;
//   const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
//   const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
//   const pageNumbers = [];

//   for (let i = startPage; i <= endPage; i++) {
//     pageNumbers.push(i);
//   }

//   return (
//     <div data-aos="fadeIn" data-aos-duration='2000'>
//       <div className='md:pl-5 py-5 md:pr-10 font-poppins'>
//         <div className='rounded-2xl'>
//           <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between pb-5'>
//             <div className='md:w-[40%]'>
//               <p className='my-auto font-bold text-xl  uppercase'>Direct Transactions History
//               </p>
//             </div>
//             <div className='flex items-center'>
//               <p className='text-end text-sm px-3 my-auto'>Show no of entity
//                 <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
//                   <option className='text-black' value="5">5</option>
//                   <option className='text-black' value="7">7</option>
//                   <option className='text-black' value="10">10</option>
//                 </select>
//               </p>
//             </div>
//           </div>
//           <div className='mb-5 border rounded-xl overflow-hidden overflow-x-auto'>
//             <table className='w-full text-center  '>
//               <thead className='text-sm uppercase font-bold border-b py-3'>
//                 <tr>
//                   <th className='py-3 px-2'>Sr.No.</th>
//                   <th className='px-2'>TrID</th>
//                   <th className='px-2'>Date</th>
//                   <th className='px-2 '>Description</th>
//                   <th className='px-2'>Credit</th>
//                   <th className='px-2'>Debit</th>
//                   <th className='px-2 '>Credit ($)</th>
//                   <th className='px-2 '>Wallet Type</th>
//                 </tr>
//               </thead>
//               <tbody className='divide-y'>
//                 {currentEntities.map((entity, index) => (
//                   <tr key={index} className='h-16 text-sm text-center rounded-md font-bold'>
//                     <td className='px-5 md:px-2'>{entity.SNo}</td>
//                     <td className='px-5 md:px-2'>{entity.Trid}</td>
//                     <td className='px-5 md:px-2'>{entity.date} {entity.time}</td>
//                     <td className='px-5 md:px-2'>{entity.description}</td>
//                     <td className='px-5 md:px-2'>{entity.credit}</td>
//                     <td className='px-5 md:px-2'>{entity.debit}</td>
//                     <td className='px-5 md:px-2'>{entity.creditdolor}</td>
//                     <td className='px-5 md:px-2'>{entity.wallet}</td>
//                   </tr>
//                 ))}
//                 {/* Add empty rows if needed */}
//                 {Array.from({ length: emptyRowCount }).map((_, index) => (
//                   <tr key={`empty-${index}`} className='h-16'>
//                     <td>&nbsp;</td>
//                     <td>&nbsp;</td>
//                     <td>&nbsp;</td>
//                     <td>&nbsp;</td>
//                     <td>&nbsp;</td>
//                     <td>&nbsp;</td>
//                     <td>&nbsp;</td>
//                     <td>&nbsp;</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//           <div className='mt-2 flex justify-between text-xs px-3'>
//             <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
//             <div className='my-auto flex justify-center items-center gap-1'>
//               <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
//               {pageNumbers.map((pageNumber) => (
//                 <button
//                   key={pageNumber}
//                   className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
//                   onClick={() => handlePageClick(pageNumber)}
//                 >
//                   {pageNumber}
//                 </button>
//               ))}
//               <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
//             </div>
//             <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from "react-redux";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ethers } from 'ethers';
import BigNumber from "bignumber.js";
import { MBCPayOut_abi } from '../../../ABI';
import { SERVER_URL, MBCPayOut_ContractAddress } from '../../../config';

export default function DirectIncome() {
  const user = useSelector((state) => state.user);
  const { CusID } = user[0];
  const { address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const [DirectIncome, setDirectIncome] = useState(0);
  const [IndirectIncome, setIndirectIncome] = useState(0);
  const [Rewards, setRewards] = useState(0);
  const [IncomeAddress, setIncomeAddress] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchAddress = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/fetchAddress?CusID=${CusID}`);
      if (response.data.status) {
        setIncomeAddress(response.data.address.income_wallet);
      } else {
        console.error("Failed to fetch address:", response.data.message);
      }
    } catch (error) {
      console.error("Error while fetching address:", error.message);
    }
  };

  const getUserDetails = async () => {
    if (!IncomeAddress) return;
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const contract = new ethers.Contract(MBCPayOut_ContractAddress, MBCPayOut_abi, provider);
      const getDetails = await contract.getuserDetails(IncomeAddress);
      setRewards(new BigNumber(getDetails[10]).dividedBy(10 ** 18).toFixed());
    } catch (err) {
      console.error("Error fetching data from contract:", err.message);
    }
  };

  const GetDirectIncome = async () => {
    try {
      const response = await axios.post(`${SERVER_URL}/api/GetDirectIncome`, { CusID });
      if (response.status === 200) {
        setDirectIncome(response.data.totalDirectIncome || 0);
      }
    } catch (error) {
      console.error("Error fetching direct income:", error.message);
    }
  };

  useEffect(() => {
    if (CusID) {
      Promise.all([GetDirectIncome(), fetchAddress()])
    }
  }, [CusID]);

  useEffect(() => {
    if (IncomeAddress && IncomeAddress !== address) {
      getUserDetails();
    }
  }, [IncomeAddress, address]);

  useEffect(() => {
    const indirect = (Rewards || 0) - (DirectIncome || 0);
    setIndirectIncome(indirect > 0 ? indirect : 0);
  }, [DirectIncome, Rewards]);
  


  return (
    <div data-aos="fadeIn" data-aos-duration='2000'>
      <div className='md:pl-5 py-5 md:pr-10 font-poppins'>
        <div className='rounded-2xl'>
          <div className='flex flex-col items-center p-20 gap-10'>
            <div className='md:w-[20%]'>
              <p className='my-auto font-bold text-xl  uppercase'>Direct Income</p>
            </div>
            <div className='border-[2px] md:w-[50%] border-white/50 rounded-md text-center px-2 py-5 shadow-md flex items-center justify-center gap-3'>
              <div><img src='/assets/wallet.png' className='mx-auto w-[60%]' alt='Wallet'></img></div>
              <div>
                <h1 className='tracking-wider uppercase text-sm'>Direct Earnings</h1>
                <p className='uppercase  font-bold text-xl my-1'>$ {DirectIncome ? Number(DirectIncome).toFixed(2) : 0}</p>
              </div>
            </div>
            <div className='md:w-[20%]'>
              <p className='my-auto font-bold text-xl  uppercase'>Level Income</p>
            </div>
            <div className='border-[2px] md:w-[50%] border-white/50 rounded-md text-center p-2 py-5 shadow-md flex items-center justify-center gap-3'>
              <div><img src='/assets/wallet.png' className='mx-auto w-[60%]' alt='Wallet'></img></div>
              <div><h1 className='tracking-wider uppercase text-sm'>Level Earnings</h1>
                <p className='uppercase  font-bold text-xl my-1'>$ {IndirectIncome ? Number(IndirectIncome).toFixed(2) : 0} </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
