import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Layout/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUser } from "@fortawesome/free-regular-svg-icons";
import { faLock, faEye, faEyeSlash, faSpinner, faXmarkCircle, faCheckCircle, faGlobe, faPhone } from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { SERVER_URL } from "../../config";

AOS.init();

const Signup = () => {

  const [visible, setVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [country, setCountry] = useState("");
  const location = useLocation();
  const [sponsorID, setSponsorID] = useState('');
  const [isRefIdReadOnly, setIsRefIdReadOnly] = useState(false);
  const [isTerms, setTerms] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [terms, setTerm] = useState(false);

  const [checkmail, setCheckMail] = useState(false);

  useEffect(() => {
    if (isTermsChecked === true && isConsentChecked === true) {
      setTerm(true)
    } else {
      setTerm(false)
    }
  }, [isTermsChecked, isConsentChecked]);


  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return params.get('ref_id');
  };

  const handleTerms = () => {
    if (isTermsChecked === true && isConsentChecked === true && isTerms === true) {
      setTerms(!isTerms);
    } else if (isTermsChecked === true && isConsentChecked === true && isTerms === false) {
      setTerm(false);
      setIsTermsChecked(false);
      setIsConsentChecked(false);
    } else {
      setTerms(!isTerms);
    }
  }

  useEffect(() => {
    const ref_id = getQueryParams();
    if (ref_id) {
      setSponsorID(ref_id);
      setIsRefIdReadOnly(true);
    }
  }, [location.search]);

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handlePasswordVisibility = () => {
    setVisible(!visible);
  };

  const SignupUser = async () => {
    if (validateForm()) {
      setLoading(true)
      if (!isTermsChecked || !isConsentChecked) {
        toast.error('Please accept the Terms and Conditions before proceeding.');
        return;
      }

      try {
        const response = await axios.post(`${SERVER_URL}/api/Signup`, {
          sponsorID, username, name, email, country, phoneNumber, password,
        });

        if (response.data.status === true) {
          toast.success('Registration successful!');
          setLoading(false)
          setCheckMail(true);
        } else {
          toast.error(response.data.message);
          setLoading(false)
        }

      } catch (error) {
        setLoading(false)
        toast.error('An error occurred. Please try again later.');
      }
    }
  };


  const validateForm = () => {
    const newErrors = {};

    const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()+])[a-zA-Z0-9!@#$%^&*()+]{8,}$/;

    if (!sponsorID) newErrors.sponsorID = "Sponsor ID is required";
    if (!username) newErrors.username = "Username is required";
    if (!name) newErrors.name = "Name is required";
    if (!phoneNumber) newErrors.phoneNumber = "Phone Number is required";
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!country) newErrors.country = "Country is required";
    if (!password) {
      newErrors.password = "Password is required";
    } else if (!password.match(passwordRegex)) {
      newErrors.password = "Password must contain at least one number, one letter, and one special character";
    }

    if (password !== confirmPassword) {
      newErrors.confirmPassword = "Password mismatch";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <div>
      <Header />
      <div
        className="sec-1 bg-black bg-cover min-h-screen font-poppins text-white pt-24 overflow-hidden"
        style={{ backgroundImage: "url(../assets/NewMBCbg.png)" }}
      >
        <div className="w-[90%] mx-auto flex justify-center items-center py-5">
          <div className="flex lg:flex-row flex-col justify-center">
            <div
              className="lg:w-[60%] w-full"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <div>
                <h1 className="md:text-6xl text-4xl  font-bold capitalize text-shadow-custom">
                  My business club
                </h1>
                <p className="font-bold md:text-lg text-sm mt-2">
                  Welcome to private membership “Business Club”
                </p>
              </div>
              <div className="mt-10 w-full">
                <img src="assets/Rectangle_39637.png" className="lg:w-10/12" />
                <div className="flex justify-end">
                  <div className="bg-white/60 sm:py-7 py-4 sm:px-5 px-2 rounded-2xl border sm:w-32 w-24 lg:-translate-x-20 md:-translate-y-24 -translate-y-10 backdrop-blur-md">
                    <img src="../assets/Icon (2).png" />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="lg:w-[40%]"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <div className="border-2 rounded-md py-5 md:px-10 px-3">
                <div>
                  <p className="sm:text-5xl text-4xl font-bold">SIGN UP</p>
                  <p className="font-bold mt-1 text-sm px-2">
                    Sign in with email address
                  </p>
                </div>
                <div className="border-b-2 py-3 pb-7">
                  <div className="mt-2 relative">
                    <input
                      type="text"
                      className={`w-full focus:outline-none rounded-lg py-3 px-10 text-[#A4A4A4] text-sm ${errors.sponsorID ? 'border-red-500' : ''}`}
                      placeholder="Enter Sponsor ID"
                      value={sponsorID}
                      onChange={(e) => setSponsorID(e.target.value)}
                      readOnly={isRefIdReadOnly}
                    />
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-[#A4A4A4] absolute left-3 top-3.5"
                    />
                  </div>
                  {errors.sponsorID && <p className="px-2 mt-1 text-xs ">{errors.sponsorID}</p>}


                  <div className="mt-2 relative">
                    <input
                      type="text"
                      className={`w-full focus:outline-none rounded-lg py-3 px-10 text-[#A4A4A4] text-sm ${errors.username ? 'border-red-500' : ''}`}
                      placeholder="Enter your UserID or Username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-[#A4A4A4] absolute left-3 top-3.5"
                    />
                  </div>
                  {errors.username && <p className="px-2 mt-1 text-xs ">{errors.username}</p>}

                  <div className="mt-2 relative">
                    <input
                      type="text"
                      className={`w-full focus:outline-none rounded-lg py-3 px-10 text-[#A4A4A4] text-sm ${errors.name ? 'border-red-500' : ''
                        }`}
                      placeholder="Enter Name"
                      value={name}
                      onChange={(e) => {
                        const filteredValue = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                        setName(filteredValue);
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-[#A4A4A4] absolute left-3 top-3.5"
                    />
                  </div>
                  {errors.name && <p className="px-2 mt-1 text-xs ">{errors.name}</p>}


                  <div className="mt-2 relative">
                    <input
                      type="email"
                      className={`w-full focus:outline-none rounded-lg py-3 px-10 text-[#A4A4A4] text-sm ${errors.email ? 'border-red-500' : ''}`}
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-[#A4A4A4] absolute left-3 top-3.5"
                    />
                  </div>
                  {errors.email && <p className="px-2 mt-1 text-xs ">{errors.email}</p>}


                  <div className="mt-2 relative">
                    <select
                      value={country}
                      onChange={handleCountryChange}
                      className={`w-full focus:outline-none rounded-lg py-3 px-10 text-[#A4A4A4] text-sm bg-white appearance-none ${errors.country ? 'border-red-500' : ''}`}
                    >
                      <option value="" disabled>
                        Select Country
                      </option>
                      {countries.map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                    <FontAwesomeIcon
                      icon={faGlobe}
                      className="text-[#A4A4A4] absolute left-3 top-3.5"
                    />
                  </div>
                  {errors.country && <p className="px-2 mt-1 text-xs ">{errors.country}</p>}


                  <div className="mt-2 relative">
                    <input
                      type="tel"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      maxLength={15}
                      className={`w-full focus:outline-none rounded-lg py-3 px-10 text-[#A4A4A4] text-sm ${errors.phoneNumber ? 'border-red-500' : ''}`}
                      placeholder="Enter Phone number"
                      value={phoneNumber}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^[0-9]*$/.test(value)) {
                          setPhoneNumber(value);
                        }
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faPhone}
                      className="text-[#A4A4A4] absolute left-3 top-3.5"
                    />
                  </div>
                  {errors.phoneNumber && <p className="px-2 mt-1 text-xs ">{errors.phoneNumber}</p>}


                  <div className="mt-3 relative">
                    <input
                      type={visible ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className={`w-full rounded-lg focus:outline-none py-3 px-10 text-[#A4A4A4] text-sm ${errors.password ? 'border-red-500' : ''}`}
                      placeholder="Enter the password"
                    />
                    <FontAwesomeIcon
                      icon={faLock}
                      className="text-[#A4A4A4] absolute left-3 top-3.5"
                    />
                    <button
                      onClick={handlePasswordVisibility}
                      className="absolute top-0 right-0 flex justify-center items-center h-[100%] w-[40px]"
                    >
                      {visible ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          size="sm"
                          className="text-gray-400"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          size="sm"
                          className="text-gray-400"
                        />
                      )}
                    </button>
                  </div>
                  {errors.password && <p className="px-2 mt-1 text-xs">{errors.password}</p>}


                  <div className="mt-3 relative">
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className={`w-full rounded-lg focus:outline-none py-3 px-10 text-[#A4A4A4] text-sm ${errors.confirmPassword ? 'border-red-500' : ''}`}
                      placeholder="Enter the confirm password"
                    />
                    <FontAwesomeIcon
                      icon={faLock}
                      className="text-[#A4A4A4] absolute left-3 top-3.5"
                    />
                  </div>
                  {errors.confirmPassword && <p className="px-2 mt-1 text-xs">{errors.confirmPassword}</p>}

                  <p className="text-xs text-center font-semibold flex justify-start gap-3 pt-5">
                    <input type="checkbox" checked={terms} onClick={handleTerms} className="" />
                    {" "}
                    By registering you agree to our
                    <a className="text-black font-semibold relative cursor-pointer" >
                      {" "}
                      Terms and Conditions.
                    </a>
                  </p>

                  <div className="mt-5 cursor-pointer" onClick={SignupUser}>
                    <div className="shadow-lg shadow-black/30 bg-white py-1 px-2 rounded-xl">
                      <div className="w-full rounded-xl py-2 bg-gradient-to-r from-[#F23985] to-[#FB9236] font-bold text-center">
                        {loading ? (
                          <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                        ) : (
                          "Sign up"
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5">
                  <p className='text-center text-sm font-semibold mt-2'>Already a member ? <a href='/Login' className='text-blue-800'>Login</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isTerms && (
        <div className='fixed z-50 inset-0 top-0 font-poppins flex justify-center items-center bg-black/50 backdrop-blur-sm'>
          <div className='w-[90%] md:w-[75%] bg-white rounded-2xl' data-aos='fade-in'>
            <div className='px-5 py-3 border-b border-black/20 flex justify-between items-center'>
              <p className='font-semibold'>Terms and Conditions</p>
              <FontAwesomeIcon className='cursor-pointer' onClick={handleTerms} icon={faXmarkCircle} />
            </div>
            <div className='px-5 py-3 overflow-hidden overflow-y-auto max-h-[400px]'>
              <h1 className='text-2xl font-bold text-center mb-4'>General Disclaimer</h1>
              <p className='text-gray-700 mt-2'>The information provided herein is intended solely for informational purposes and does not constitute financial, investment, legal, or any other form of advice. My Business Club operates as a private membership club dedicated to educational purposes. Membership benefits include access to a suite of services, including educational content, podcasts, and travel benefits, tailored to varying levels of membership.</p>
              <p className='text-gray-700 mt-2'>It is important to note that My Business Club does not guarantee any return on investment. We do not offer investment opportunities or promise financial gains. Rather, our focus is on providing valuable educational resources and services to our members.</p>
              <p className='text-gray-700 mt-2'>Membership in My Business Club involves the exchange of membership fees for access to the benefits and services. We strive to provide high-quality educational content and experiences.</p>
              <p className='text-gray-700 mt-2'>We encourage individuals to make informed decisions based on their own due diligence and to seek advice from qualified professionals if needed. By continuing your participation in My Business Club, you acknowledge and accept the terms outlined in this disclaimer.</p>
              <h1 className='text-2xl font-bold text-center mb-4'>Income Disclaimer</h1>
              <p className='text-gray-700 mt-2'>We strive to accurately represent My Business Club, its membership benefits, and the educational content it offers, as well as the My Business Club referral rewards plan and its potential for benefits. My Business Club accelerates the accumulation of knowledge and access to resources within, through member referrals.</p>
              <p className='text-gray-700 mt-2'>There are no guarantees regarding benefits or rewards from the My Business Club membership opportunity. The basic membership reward plan offers members anticipated benefits based on the value of membership acquired.</p>
              <p className='text-gray-700 mt-2'>Any mention of potential benefits made in this presentation solely describes the My Business Club membership plan itself. Our Service Agreement and regulatory requirements strictly prohibit members or presenters from making income claims, as such claims may be considered enticement.</p>
              <p className='text-gray-700 mt-2'>The examples provided may showcase exceptional results that may not be typical for the average member and are not intended to represent or guarantee that anyone will achieve the same or similar results. The benefits, achievements, or rank levels are dependent upon individual members' skills, ambition, commitment, expertise, activity, and demographic factors. As with any educational programme or membership club, individual results may vary.</p>
              <p className='text-gray-700 mt-2'>It is crucial that the use of our information, products, and services is based on your own due diligence. By engaging with My Business Club, you agree that our company is not liable for any success or failure of your educational pursuits or business endeavours directly or indirectly related to the acquisition and use of our membership, information, referral plan, products, or services.</p>
              <div className='mt-4'>
                <h1 className="text-2xl font-bold text-center mb-4">Terms and Conditions</h1>
                <div className="space-y-4">
                  <div>
                    <h2 className="text-lg font-semibold">1. Application and Agreement</h2>
                    <ul className="list-disc list-inside mt-2 space-y-2">
                      <li>Applicants must be 18 years or older to apply.</li>
                      <li>By applying, you authorize My Business Club to store and use your data.</li>
                      <li>The agreement is valid from the sign-up date and memberships are valid for 1 year from the payment date.</li>
                      <li>My Business Club reserves the right to accept or decline applications without reason.</li>
                    </ul>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold">2. Fees and Payments</h2>
                    <ul className="list-disc list-inside mt-2 space-y-2">
                      <li>There is no registration or processing fee.</li>
                      <li>You agree to pay for the chosen plan, which is considered payment for memberships listed on the company website/application.</li>
                      <li>All memberships must be purchased via crypto payments.</li>
                      <li>Memberships can be added or removed at the company's discretion.</li>
                    </ul>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold">3. Distributorship and Memberships</h2>
                    <ul className="list-disc list-inside mt-2 space-y-2">
                      <li>Upon registration, you become an independent distributor and are entitled to earn referral rewards.</li>
                      <li>Purchasing memberships is optional. You can choose to buy memberships on day one at full cost and unlock the relevant benefits.</li>
                      <li>Membership refunds are not available under any circumstances.</li>
                    </ul>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold">4. Membership Restrictions</h2>
                    <ul className="list-disc list-inside mt-2 space-y-2">
                      <li>Independent distributors cannot make promises to potential distributors.</li>
                      <li>The company is not liable for promises made by other distributors.</li>
                      <li>Certain membership benefits may have their own country restrictions; please check the local laws in your region before activating those benefits.</li>
                    </ul>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold">5. Distributor Rights and Responsibilities</h2>
                    <ul className="list-disc list-inside mt-2 space-y-2">
                      <li>You are an independent distributor, not an employee or agent of the company.</li>
                      <li>Membership sales are governed by the business plan and company policies.</li>
                      <li>You acknowledge that the payment is for membership purchase and not a refundable deposit.</li>
                      <li>Your income depends on the sales value made under you according to the business plan.</li>
                      <li>Becoming a distributor is optional. You may also be required to make personal membership purchases to qualify for rewards.</li>
                    </ul>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold">6. Incentive Program</h2>
                    <ul className="list-disc list-inside mt-2 space-y-2">
                      <li>Every membership package purchased by a new distributor in your team adds an incentive to your account.</li>
                      <li>You agree to the company's incentive programme details.</li>
                      <li>You will be promoted through the distributor ranks based on performance.</li>
                      <li>You will receive a copy of the entire scheme outlining your rights and obligations.</li>
                    </ul>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold">7. Reward Payments</h2>
                    <ul className="list-disc list-inside mt-2 space-y-2">
                      <li>Your rewards will be available to withdraw anytime in your back-office wallet.</li>
                      <li>Rewards are paid by smart contract.</li>
                      <li>The company's decision on reward distribution is final.</li>
                      <li>Members are responsible for their taxes in their own countries.</li>
                    </ul>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold">8. Company Rights</h2>
                    <ul className="list-disc list-inside mt-2 space-y-2">
                      <li>My Business Club can modify its business plan, rules, memberships, and income plan at any time.</li>
                      <li>Distributors are responsible for keeping up to date with these changes.</li>
                    </ul>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold">9. Distributor Conduct</h2>
                    <ul className="list-disc list-inside mt-2 space-y-2">
                      <li>Distributors must perform their duties in a way that benefits the company and complies with all laws.</li>
                      <li>The company is not liable for claims arising from unauthorised presentations by distributors.</li>
                      <li>Distributors are responsible for indemnifying the company against third-party damages.</li>
                      <li>Distributors must refrain from using unauthorised marketing materials.</li>
                    </ul>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold">10. Termination</h2>
                    <ul className="list-disc list-inside mt-2 space-y-2">
                      <li>In case of a distributor's death, the distributorship and benefits can be transferred to a nominated beneficiary as per the member’s Will.</li>
                      <li>My Business Club can cancel distributorships for various reasons, including violation of company policies.</li>
                      <li>Upline and downline distributors will continue to receive their earned rewards even after termination.</li>
                      <li>Distributors who cease to be distributors cannot be readmitted for at least six months. Readmission is at the company's discretion.</li>
                      <li>Distributors are not entitled to benefits during termination.</li>
                      <li>Upon resignation, distributors must submit a written notice and are not entitled to refunds.</li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold">11. Distributor Policies</h2>
                    <ul className="list-disc list-inside mt-2 space-y-2">
                      <li>Distributors cannot introduce or sell unauthorised products or services through the network.</li>
                      <li>Reselling company memberships below the fixed price is prohibited.</li>
                      <li>Repackaging of company memberships is not allowed.</li>
                      <li>Only official company materials can be used for marketing.</li>
                      <li>Promotional materials cannot be reproduced or misused.</li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold">12. Website Disclaimer</h2>
                    <ul className="list-disc list-inside mt-2 space-y-2">
                      <li>Distributors are solely responsible for any claims related to membership sales, licensing, marketing, and taxation.</li>
                      <li>The company is not liable for problems arising from website connectivity, technical issues, or any outdated information on websites.</li>
                      <li>Distributors indemnify My Business Club against all claims made by third parties related to their conduct.</li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold">13. Distributor Training and Requirements</h2>
                    <ul className="list-disc list-inside mt-2 space-y-2">
                      <li>Distributors are encouraged to regularly attend on and offline business seminars and trainings to understand the terms, business nature, and memberships.</li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold">14. Cross-Sponsoring and Recruiting</h2>
                    <ul className="list-disc list-inside mt-2 space-y-2">
                      <li>Cross-sponsoring and cross-recruiting are strictly prohibited.</li>
                      <li>Cross-sponsoring involves influencing a distributor to join under a different downline.</li>
                      <li>Cross-recruiting involves recruiting a distributor for another network marketing company that is not directly enrolled by you in MBC.</li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold">15. Use of Trademarks</h2>
                    <ul className="list-disc list-inside mt-2 space-y-2">
                      <li>Distributors cannot use My Business Club trademarks except to promote the company.</li>
                      <li>Displaying the company name or logo outside the official materials is strictly forbidden.</li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold">16. Password Security</h2>
                    <ul className="list-disc list-inside mt-2 space-y-2">
                      <li>Distributors are responsible for keeping their passwords confidential.</li>
                      <li>The company is not liable for any consequences of password misuse.</li>
                    </ul>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold">17. Amendments</h2>
                    <ul className="list-disc list-inside mt-2 space-y-2">
                      <li>All written amendments to this agreement must be approved by the company to be valid.</li>
                    </ul>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold">18. Force Majeure</h2>
                    <ul className="list-disc list-inside mt-2 space-y-2">
                      <li>Neither party is liable for delays caused by circumstances beyond their control.</li>
                    </ul>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold">19. Dispute Resolution</h2>
                    <ul className="list-disc list-inside mt-2 space-y-2">
                      <li>Any disputes will be addressed through a written complaint to the company.</li>
                      <li>The company may refer the matter to a sole arbitrator chosen by them.</li>
                      <li>Multiple accounts are not allowed, each acc must have own KYC.</li>
                      <li>Anyone found to be gaming the system risks termination of accounts and income</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className='flex items-start gap-2 mt-3'>
                <input type='checkbox' checked={isTermsChecked} onChange={() => setIsTermsChecked(!isTermsChecked)}></input>
                <p className='text-gray-700 -translate-y-1'>By checking this box, you acknowledge that you have read and understood all the Terms and Conditions mentioned herein and agree to abide by them.</p>
              </div>
              <div className='flex items-start gap-2 mt-3'>
                <input type='checkbox' checked={isConsentChecked} onChange={() => setIsConsentChecked(!isConsentChecked)}></input>
                <p className='text-gray-700 -translate-y-1'>By checking this box, <span className='font-bold text-black'>You confirm your voluntary consent to join My Business Club as a member without any pressure or coercion from your sponsor and are 100% responsible for your decision to participate in My Business Club and any affiliated partners or projects.</span></p>
              </div>
            </div>
            <div className='px-5 py-3 flex justify-center border-t border-black/20'>
              <button className='bg-gradient-to-r from-[#E43875] to-[#F98736] text-white font-semibold px-5 py-2 rounded-md shadow-md flex justify-center items-center overflow-hidden group duration-700' onClick={() => { handleTerms() }}>
                <FontAwesomeIcon icon={faCheckCircle} className='duration-700 -translate-x-10 group-hover:translate-x-9 -rotate-180 group-hover:rotate-0 text-[#00FF00]' />
                <p className='duration-700 -translate-x-2 group-hover:translate-x-24' >Confirm</p>
              </button>
            </div>
          </div>
        </div>
      )}
      {checkmail && (
        <div className='rounded-2xl z-50 w-full modal-overlay fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-10 backdrop-blur-md'>
          <div className='lg:w-[30%] flex flex-col justify-center bg-white p-10 rounded-lg relative'>
            <p className='text-black text-center text-xl font-semibold'>Verify Your Email</p>
            <p className='text-gray-700 text-center mt-3'>
              A verification link has been sent to your registered email address. Please check your inbox and click the link to verify your email.
            </p>
            <div className='flex justify-center mt-5 gap-10'>
              <a href="https://mail.google.com"
                className='w-[50%] rounded-xl py-2 bg-gradient-to-r from-[#F23985] to-[#FB9236] font-bold text-center'
                target="_blank" rel="noopener noreferrer">
                <p className='text-center'>Check Mail</p>
              </a>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default Signup;

